<div class="d-flex justify-content-end choose-both mb-5">
  <div *ngIf="canSelectBoth">
    {{'increase-economy' | translate}}
    <button class="re-button re-button-sm yellow" [innerHTML]="'choose-electricity-and-gas' | translate"
            (click)="goBackSelectBoth()"></button>
  </div>
</div>
<div class="packs-preview" *ngIf="energyPacks && energyPacks.length" [ngStyle]="energyPacksCss">
  <app-packs-feature-name-column [showAllFeatures]="showAllEnergyFeatures"></app-packs-feature-name-column>
  <ng-container *ngFor="let pack of energyPacks; let i = index">
    <app-packs-preview-column [pack]="pack" [isLast]="i === energyPacks.length - 1"
                              [(showAllFeatures)]="showAllEnergyFeatures"
                              (showAllFeaturesChange)="calcRowCount()"
                              (packSelected)="energyPackSelected(pack)"></app-packs-preview-column>
  </ng-container>
</div>
<div class="packs-preview" *ngIf="gasPacks && gasPacks.length" [ngStyle]="gasPacksCss">
  <app-packs-feature-name-column [showAllFeatures]="showAllGasFeatures"></app-packs-feature-name-column>
  <ng-container *ngFor="let pack of gasPacks; let i = index">
    <app-packs-preview-column [pack]="pack" [isLast]="i === gasPacks.length - 1"
                              [(showAllFeatures)]="showAllGasFeatures"
                              (showAllFeaturesChange)="calcRowCount()"
                              (packSelected)="gasPackSelected(pack)"></app-packs-preview-column>
  </ng-container>
</div>
<div class="d-flex justify-content-between pb-5 mt-5">
  <button class="re-button re-button-sm" (click)="goBack()">{{ 'prev-step' | translate }}</button>
  <button class="re-button re-button-sm" (click)="goNext()" [disabled]="!stepValid">{{'next-step' | translate}}
    <svg-icon name="left-arrow"
              [svgStyle]="{'width.px': '18', 'height.px': '18', 'transform': 'scaleX(-1)'}"></svg-icon>
  </button>
</div>
