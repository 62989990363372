import { Injectable } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';

@Injectable({
  providedIn: 'root',
})
export class SvgIconsLoaderService {

  constructor(
    private iconReg: SvgIconRegistryService,
  ) {
  }

  public init(): void {
    this.iconReg.loadSvg('assets/icons/progress-step-background.svg', 'progress-step').subscribe();
    this.iconReg.loadSvg('assets/icons/check.svg', 'check').subscribe();
    this.iconReg.loadSvg('assets/icons/caret.svg', 'caret').subscribe();
    this.iconReg.loadSvg('assets/icons/spinner-yellow.svg', 'spinner-yellow').subscribe();
    this.iconReg.loadSvg('assets/icons/spinner-red.svg', 'spinner-red').subscribe();
    this.iconReg.loadSvg('assets/icons/spinner-green.svg', 'spinner-green').subscribe();
    this.iconReg.loadSvg('assets/icons/spinner.svg', 'spinner').subscribe();
    this.iconReg.loadSvg('assets/icons/left-arrow.svg', 'left-arrow').subscribe();
    this.iconReg.loadSvg('assets/icons/check-circle.svg', 'check-circle').subscribe();
    this.iconReg.loadSvg('assets/icons/energy.svg', 'energy').subscribe();
    this.iconReg.loadSvg('assets/icons/gas.svg', 'gas').subscribe();
    this.iconReg.loadSvg('assets/icons/pack-header-bottom.svg', 'pack-header-bottom').subscribe();
    this.iconReg.loadSvg('assets/icons/upload.svg', 'upload').subscribe();
    this.iconReg.loadSvg('assets/icons/expand.svg', 'expand').subscribe();
  }
}
