import { Injectable } from '@angular/core';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { SendEmailDialogComponent } from './components/send-email-dialog/send-email-dialog.component';
import { IframeService } from '../../services/iframe.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogsService {

  constructor(
    private matDialog: MatDialog,
    private iframeService: IframeService,
    private trans: TranslateService,
  ) {
  }

  public alertDialog(config: MatDialogConfig): MatDialogRef<any> {
    if (!config) {
      config = {};
    }
    if (!config.panelClass) {
      config.panelClass = 're-custom-dialog-panel';
    }
    if (!config.disableClose) {
      config.disableClose = true;
    }
    this.iframeService.scrollParentTop();
    return this.matDialog.open(AlertDialogComponent, config);
  }

  public sendEmailDialog(config: MatDialogConfig): MatDialogRef<any> {
    if (!config) {
      config = {};
    }
    if (!config.panelClass) {
      config.panelClass = 're-custom-dialog-panel';
    }
    this.iframeService.scrollParentTop();
    return this.matDialog.open(SendEmailDialogComponent, config);
  }

  public async showUnknownErrorOccurred(): Promise<MatDialogRef<any>> {
    return this.alertDialog({
      data: {
        title: await this.trans.get('errors.error').toPromise(),
        html: await this.trans.get('errors.unknown-error').toPromise(),
      },
    });
  }

  public openLoadingDialog(title: string): MatDialogRef<any> {
    const config: MatDialogConfig = {data: {title}, panelClass: 're-custom-dialog-panel'};
    this.iframeService.scrollParentTop();
    return this.matDialog.open(LoadingDialogComponent, config);
  }
}
