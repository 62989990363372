<div class="mb-3"></div>
<div class="main-area row">
  <div class="left col-12 col-sm-8">
    <app-pack-details *ngIf="hasEnergy" [pack]="energyPack" [isX2]="isX2"
                      [ngClass]="{'pb-3': hasGas}"></app-pack-details>
    <app-pack-details *ngIf="hasGas" [pack]="gasPack" [isX2]="isX2"></app-pack-details>
  </div>
  <div class="right col-12 col-sm-4 mt-4 mt-sm-0">
    <app-mini-cart [packs]="packs" [isX2]="isX2"></app-mini-cart>
    <div class="card mt-3 ">
      <div class="content p-big">
        <div class="re-button re-button-sm" (click)="goNext()" tabindex="0">
          {{'step3.change-provider-now' | translate}}
        </div>
        <div class="re-button re-button-sm white mt-3" (click)="sendClientEmail()" *ngIf="!globalModel.isExpired">
          {{'step3.send-email-button-text' | translate}}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-between py-3">
  <button class="re-button re-button-sm" (click)="goBack()">{{ 'prev-step' | translate }}</button>
</div>
