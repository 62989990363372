import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { AppBrowserModule } from './app/app.browser.module';
import * as Sentry from '@sentry/angular';
import { Event, EventHint } from '@sentry/angular';
import { Integrations } from '@sentry/tracing';


if (environment.production) {

  Sentry.init({
    dsn: 'https://1b685012e4d346238ae282c90a60427d@sentry.tdrs.ro/6',
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', 'https://calculator.restartenergy.ro'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    beforeSend: (event: Event, hint?: EventHint) => {
      if (hint.originalException === 'Timeout') {
        return null;
      }
      return event;
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
    release: '1.4.8',
    dist: 'tdr',
  });

  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppBrowserModule)
    .catch(err => console.error(err));
});
