<label [for]="id" [innerHTML]="label"></label>
<div ngbDropdown #dropdown="ngbDropdown">
  <div class="dropdown-toggle"
       [ngClass]="{active: dropdown.isOpen()}">
    <div [id]="id" class="form-control input"
         [class.is-invalid]="showAsInvalid"
         tabindex="0"
         ngbDropdownAnchor
         (click)="toggleDropdown($event)"
         (keydown.Space)="toggleDropdown($event)"
         [innerHTML]="displayText || placeholder">
    </div>
    <app-caret></app-caret>
  </div>
  <div ngbDropdownMenu>
    <button ngbDropdownItem *ngFor="let option of options" (click)="selectOption(option)"
            [innerHTML]="optionLabelFormatter(option)"></button>
  </div>
</div>
