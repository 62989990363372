import { Component, OnInit } from '@angular/core';
import { StepWithPacksDirective } from '../step-with-packs.directive';
import { ApiService } from '../../../services/api.service';
import { SendEmailDialogDataResult } from '../../shared/components/send-email-dialog/send-email-dialog.component';
import { DialogsService } from '../../shared/dialogs.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss'],
})
export class StepThreeComponent extends StepWithPacksDirective implements OnInit {
  constructor(
    private apiService: ApiService,
    private dialogs: DialogsService,
    private trans: TranslateService,
  ) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public async sendClientEmail(): Promise<void> {
    const dialog = this.dialogs.sendEmailDialog({
      data: {
        // fullName: this.globalModel.step4?.fullName,
        firstName: this.globalModel.step4?.firstName,
        lastName: this.globalModel.step4?.lastName,
        email: this.globalModel.step4?.email,
        globalModel: this.globalModel,
      },
    });
    dialog.afterClosed().subscribe(async (result: SendEmailDialogDataResult) => {
      if (!result) {
        return;
      }
      switch (result.code) {
        case 'alert-success':
          this.dialogs.alertDialog({
            data: {
              html: await this.trans.get('step3.send-email-success-message').toPromise(),
              title: ' ',
            },
          });
          break;
        case 'alert-unknown-error':
          await this.dialogs.showUnknownErrorOccurred();
          break;
        case 'session-already-finalized':
        case 'invalid-session':
          this.dialogs.alertDialog({
            data: {html: await this.trans.get('errors.' + result.code).toPromise()},
          }).afterClosed().subscribe(() => {
            window.location.reload();
          });
          break;
        case 'invalid-captcha':
          this.dialogs.alertDialog({
            data: {html: await this.trans.get('errors.invalid-captcha').toPromise()},
          }).afterClosed().subscribe(() => {
            this.sendClientEmail();
          });
      }
      if (result.email && result.firstName && result.lastName) {
        this.globalModel.step4.email = result.email;
        this.globalModel.step4.firstName = result.firstName;
        this.globalModel.step4.lastName = result.lastName;
      }
    });
  }
}
