import { AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges } from '@angular/core';
import { ReCarouselSlideComponent } from './re-carousel-slide/re-carousel-slide.component';

@Component({
  selector: 're-carousel',
  templateUrl: './re-carousel.component.html',
  styleUrls: ['./re-carousel.component.scss'],
})
export class ReCarouselComponent implements OnChanges, AfterViewInit {
  @Input()
  public currentStep: number;

  @Output()
  public currentStepChange: EventEmitter<number> = new EventEmitter<number>();

  @ContentChildren(ReCarouselSlideComponent) public contentChildren!: QueryList<ReCarouselSlideComponent>;

  public firstLoaded: boolean;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentStep) {
      this.updateVisibility();
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateVisibility();
    });
  }

  private updateVisibility(): void {
    if (!this.contentChildren) {
      return;
    }
    const arr = this.contentChildren.toArray();
    const crtVisible: number[] = [];
    let lastVisible: number;
    let toSetVisible: number;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].stepIndex === this.currentStep) {
        toSetVisible = i;
      } else if (arr[i].visible) {
        crtVisible.push(i);
        lastVisible = i;
      }
    }
    for (const crtVis of crtVisible) {
      if (crtVis < toSetVisible) {
        this.safeCallFnAtIndex(arr[crtVis], cmp => cmp.hideToLeft());
      } else {
        this.safeCallFnAtIndex(arr[crtVis], cmp => cmp.hideToRight());
      }
    }
    if (this.firstLoaded) {
      if (lastVisible > toSetVisible) {
        this.safeCallFnAtIndex(arr[toSetVisible], cmp => cmp.showFromLeft());
      } else {
        this.safeCallFnAtIndex(arr[toSetVisible], cmp => cmp.showFromRight());
      }
    } else {
      this.firstLoaded = true;
      this.safeCallFnAtIndex(arr[toSetVisible], cmp => cmp.justShow());
    }
  }

  private safeCallFnAtIndex(component: ReCarouselSlideComponent, fn: (component: ReCarouselSlideComponent) => void): void {
    if (component) {
      fn(component);
    }
  }
}
