<div class="background">
  <div class="top-background"></div>
  <div class="rectangle">
    <img src="assets/images/rectangle.svg" alt=" "/>
  </div>
  <div class="ellipse">
    <img src="assets/images/ellipse.svg" alt=" "/>
  </div>
  <div class="bubbles">
    <img src="assets/images/bubbles.svg" alt=" "/>
  </div>
</div>
<!--<app-languages-bar></app-languages-bar>-->
<div class="container">
  <div class="row pt-5 pb-2">
    <div class="col-12">
      <app-progress-bar [(currentStep)]="step"></app-progress-bar>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <re-carousel [(currentStep)]="step">
        <re-carousel-slide [stepIndex]="1" (slideEnter)="step1.onEnter()" visible="true">
          <app-step-one (next)="nextRequested()" [globalModel]="globalModel" #step1></app-step-one>
        </re-carousel-slide>
        <re-carousel-slide [stepIndex]="2" (slideEnter)="step2.onEnter()">
          <app-step-two (next)="nextRequested()" (back)="backRequested()" [globalModel]="globalModel"
                        #step2></app-step-two>
        </re-carousel-slide>
        <re-carousel-slide [stepIndex]="3" (slideEnter)="step3.onEnter()">
          <app-step-three (next)="nextRequested()" (back)="backRequested()"
                          [globalModel]="globalModel" #step3></app-step-three>
        </re-carousel-slide>
        <re-carousel-slide [stepIndex]="4" (slideEnter)="step4.onEnter()">
          <app-step-four (back)="backRequested()" (resetToFirst)="resetToFirstRequested()" [globalModel]="globalModel"
                         #step4></app-step-four>
        </re-carousel-slide>
      </re-carousel>
    </div>
  </div>
  <!--  <div class="row">-->
  <!--    <div class="col-12">-->
  <!--      <pre>{{globalModel | json}}</pre>-->
  <!--    </div>-->
  <!--  </div>-->
</div>
