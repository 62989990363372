import { Component, HostBinding, Input } from '@angular/core';
import PackModel from '../../models/pack.model';

@Component({
  selector: 'app-pack-price',
  templateUrl: './pack-price.component.html',
  styleUrls: ['./pack-price.component.scss'],
})
export class PackPriceComponent {
  @Input()
  public pack: PackModel;

  @Input()
  public isX2 = false;

  @Input()
  public whiteUnit: boolean;

  @Input()
  @HostBinding('class.is-md')
  public size: 'md' | undefined;


  public get monthlyPrice(): number {
    return this.isX2 ? this.pack.monthlyPriceX2 : this.pack.monthlyPrice;
  }

  public get unitPrice(): number {
    return this.isX2 ? this.pack.unitPriceX2 : this.pack.unitPrice;
  }
}
