<ng-container *ngIf="pack">
  <div class="card">
    <div class="header container-fluid">
      <div class="row">
        <div class="col-7 col-sm-6 col-md-7">
          <div class="pack-title" [innerHTML]="'packs.' + pack.id | translate"></div>
          <!--          + (isX2 ? '-x2' : '')-->
          <div *ngIf="isX2" class="x2">{{'packs.x2' | translate}}</div>
          <div class="first-letter-upercase"
               [innerHTML]="'features.values.' + pack.features.description | translate"></div>
        </div>
        <div class="col-5 col-sm-6 col-md-5 text-right">
          <div class="price">
            <app-pack-price [pack]="pack" [isX2]="isX2" size="md"></app-pack-price>
          </div>
          <div class="">
            <span [innerHTML]="'features.price-formatted' | translate"></span>&nbsp;
            <span [innerHTML]="'vat-included' | translate"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="green h2">
        Abonamentul include
      </div>
      <div class="d-grid features-grid my-2 my-md-4">
        <ng-container *ngFor="let feature of features">
          <ng-container *ngIf="feature.key !== 'description'">
            <div class="d-flex align-items-center">
              <svg-icon name="check-circle" class="icon" [class.fill-green]="pack.features[feature.key]"
                        *ngIf="feature.type === 'boolean'"></svg-icon>
            </div>
            <div><span [innerHTML]="'features.' + feature.key | translate"></span>
              <span *ngIf="feature.type === 'string'">:
            <b [innerHTML]="'features.values.' + pack.features[feature.key] | translate"></b>
            </span>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="pack.extraOptions && pack.extraOptions.length">
        <div class="green h2">
          Adaugă extraopțiuni
        </div>
        <div *ngFor="let option of pack.extraOptions; let i = index;">
          <div class="rounded-corners-lg border-gray p-3 mt-2"
               (click)="pack.extraOptionsState[option.id] = !pack.extraOptionsState[option.id]">
            <app-re-checkbox
              [(ngModel)]="pack.extraOptionsState[option.id]">
              <div class="d-flex align-items-center cursor-pointer">
                <div class="ps-2 flex-grow-1">
                  <b [innerHTML]="'extra-options.' + option.id | translate"></b>
                  <div [innerHTML]="'extra-options.' + option.id + '-short-desc' | translate"></div>
                </div>

                <div class="extra-option-help"
                     [ngbPopover]="'extra-options.' + option.id + '-description' | translate"
                     #popovers="ngbPopover"
                     placement="bottom" triggers="mouseenter:mouseleave">?
                </div>
              </div>
            </app-re-checkbox>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<!--(mouseenter)="showPopover(i, 'extra-options.' + option.id + '-description', $event);"-->
<!--<ng-template #popoverHtmlTemplate let-transKey="transKey">-->
<!--    <div>{{transKey}}</div>-->
<!--    <div [innerHTML]="transKey | translate"></div>-->
<!--</ng-template>-->
