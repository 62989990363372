import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// export const EmailRegex = '^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:[^\s\][a-zA-Z0-9-]+)*$';

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {

  public readonly recaptchaSiteKey = '6Lf4_CIaAAAAAPC8BAkU3DnRZYbnowc_fDTjriX3';

  private url: string;

  public get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.isDebug = this.isBrowser && window.location.search.indexOf('debug=true') > -1;
    this.setUrl();
  }

  public isDebug = false;

  public gasDisabled: boolean = true;

  public get apiUrl(): string {
    return this.url;
  }


  private setUrl(): void {
    if (!this.isBrowser) {
      return;
    }
    if (window.location.href.indexOf('://localhost') !== -1) {
      this.url = 'http://localhost:7311/api/';
    } else if (window.location.href.indexOf('calculator.restartenergy.ro') !== -1 || window.location.href.indexOf('dev.calculator.restartenergy') !== -1
      || window.location.href.indexOf('restart.form.tdr') !== -1) {
      this.url = '/api/api/';
    } else {
      this.url = 'https://calculator.restartenergy.ro/api/api/';
    }
  }
}
