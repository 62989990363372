import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IframeService {

  constructor() {
  }

  public sendMessageToParent(message: any): void {
    const inIframe = () => {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    };
    if (!inIframe()) {
      // console.log("not in iframe:", message);
      return;
    }
    try {
      parent.postMessage(message, 'https://restartenergy.ro');
    } catch (exc) {
      try {
        parent.postMessage(message, 'http://restartenergy.ro');
      } catch (exc) {
      }
    }
  }
  public scrollParentTop(): void {
    this.sendMessageToParent({scrollTop: true});
  }
}
