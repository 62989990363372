<div class="rounded-corners" (click)="toggle($event)">
  <div class="check-circle-wrapper">
    <div class="check-circle" [class.checked]="getValue">
      <svg-icon name="check" class="icon" [svgStyle]="{fill: 'white', 'vertical-align': 'top'}"
                *ngIf="getValue"></svg-icon>
    </div>
  </div>
  <div class="content-wrapper flex-grow-1">
    <ng-content></ng-content>
  </div>
</div>

