import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'ngx-webstorage';
import GlobalModel from '../models/global-model';
import { SessionModel } from '../models/session-model';
import { PacksService } from '../packs/packs.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
    private constants: ConstantsService,
    private http: HttpClient,
    private sessionSt: SessionStorageService,
    private packsService: PacksService,
  ) {
  }

  public buildSessionAndSave(globalObj: GlobalModel): Promise<any> {
    const session: Partial<SessionModel> = {};

    session.locality = globalObj.step1.locality;
    session.offerType = globalObj.step1.offerType;

    if (session.offerType !== 'gas') {
      session.energyConsumption = globalObj.step1.consumptions.energy;
      session.energyPackType = this.packsService.getPackSpecificId(globalObj.step2.energyPack.id);
      session.energyPackExtraOptions = this.packsService.getSelectedExtraOptions(globalObj.step2.energyPack);
    }
    if (session.offerType !== 'energy') {
      session.gasConsumption = globalObj.step1.consumptions.gas;
      session.gasPackType = this.packsService.getPackSpecificId(globalObj.step2.gasPack.id);
      session.gasPackExtraOptions = this.packsService.getSelectedExtraOptions(globalObj.step2.gasPack);
    }

    session.firstName = globalObj.step4.firstName;
    session.lastName = globalObj.step4.lastName;
    session.cnp = globalObj.step4.cnp;
    session.email = globalObj.step4.email;
    session.phoneNumber = globalObj.step4.phoneNumber;

    // session.acceptPropertyDocumentDeclaration = globalObj.step4.acceptPropertyDocumentDeclaration;
    session.acceptDataProcessing = globalObj.step4.acceptDataProcessing;
    session.acceptProviderRightToAccessNetworkOperatorDatabase = globalObj.step4.acceptProviderRightToAccessNetworkOperatorDatabase;
    session.subscribeRestartEnergyPromotions = globalObj.step4.subscribeRestartEnergyPromotions;
    session.subscribeRestartEnergyPartnersPromotions = globalObj.step4.subscribeRestartEnergyPartnersPromotions;

    session.acceptMarketSurveys = globalObj.step4.acceptMarketSurveys;
    session.acceptCustomerSatisfactionVerificationCampaigns = globalObj.step4.acceptCustomerSatisfactionVerificationCampaigns;

    session.voucherCode = globalObj.step4.voucherCode;

    session.declaredHasLocationRight = globalObj.step4.declaredHasLocationRight;
    session.declaredNoLocationLitigation = globalObj.step4.declaredNoLocationLitigation;

    session.housingQualityOfApplicant = globalObj.step4.housingQualityOfApplicant;
    session.otherHousingQualityOfApplicant = globalObj.step4.housingQualityOfApplicantOther;

    session.id = this.sessionSt.retrieve('session_id');

    return this.saveSession(session as SessionModel);
  }

  public async registerSession(session: SessionModel): Promise<any> {
    const url = this.constants.apiUrl + 'SessionsApi/Create';
    const response = await this.http.post<{ model: SessionModel }>(url, session).toPromise();
    session = response.model;
    if (session.id) {
      this.sessionSt.store('session_id', session.id);
    }
    return session;
  }

  public updateSession(session: SessionModel): Promise<any> {
    const url = this.constants.apiUrl + 'SessionsApi/Update';
    return this.http.post(url, session).toPromise();
  }

  public saveSession(session: SessionModel): Promise<any> {
    if (session.id) {
      return this.updateSession(session);
    } else {
      return this.registerSession(session);
    }
  }

  public async finalizeSession(captcha: string): Promise<boolean> {
    const url = this.constants.apiUrl + 'SessionsApi/Finalize';

    const id = this.sessionSt.retrieve('session_id');

    const body = {sessionId: id, captcha};
    const result = await this.http.post<boolean>(url, body).toPromise();
    if (result) {
      this.clearSession();
    }
    return result;
  }

  public clearSession(): void {
    this.sessionSt.clear('session_id');
  }

  public async sendClientEmail(model: { email: string, fullName: string, captcha: string, sessionId?: string },
                               globalModel: GlobalModel): Promise<string> {
    const url = this.constants.apiUrl + 'SessionsApi/SendClientEmail';

    await this.buildSessionAndSave(globalModel);

    model.sessionId = this.sessionSt.retrieve('session_id');
    return await this.http.post<string>(url, model).toPromise();
  }
}
