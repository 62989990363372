import { AfterViewInit, Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ReBaseInputDirective } from '../re-base-input.directive';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 're-select-input',
  templateUrl: './select-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectInputComponent),
      multi: true,
    },
  ],
})
export class SelectInputComponent extends ReBaseInputDirective<string> implements AfterViewInit, OnInit {

  public id: string;

  public displayText: string;

  @ViewChild('dropdown') public dropdown: NgbDropdown;

  @Input()
  public options: any[];


  @Input()
  public valueFormatter: (option: any) => any = option => option;
  @Input()
  public optionLabelFormatter: (option: any) => any = option => option;
  @Input()
  public inputFormatter: (option: any) => any = option => option;

  public ngAfterViewInit(): void {
    this.dropdown.openChange.subscribe(open => {
      if (!open) {
        // this.control.markAsTouched();
        this.onTouched(this.value);
      }
    });
  }

  public selectOption(option: any): void {
    // this.control.setValue(this.valueFormatter(option));
    this.value = this.valueFormatter(option);
    this.onChange(this.value);
    this.onTouched(this.value);
  }

  public toggleDropdown($event: Event): void {
    $event.preventDefault();
    this.dropdown.toggle();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.id = 'select-' + Math.random().toString().substr(2);
    // this.control.valueChanges.subscribe(value => {
    //   const option = this.options.find(opt => this.valueFormatter(opt) === value);
    //   if (option) {
    //     this.displayText = this.inputFormatter(option);
    //   }
    // });
  }

  protected onChangeHook = (value: string) => {
    const option = this.options.find(opt => this.valueFormatter(opt) === value);
    if (option) {
      this.displayText = this.inputFormatter(option);
    }
  };
}
