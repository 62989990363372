import { Component, ErrorHandler, Inject, OnInit, PLATFORM_ID, QueryList, ViewChildren } from '@angular/core';
import { StepWithPacksDirective } from '../step-with-packs.directive';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReFileFieldComponent } from '../../shared/components/re-file-field/re-file-field.component';
import { FilesService } from '../../../services/files.service';
import { ApiService } from '../../../services/api.service';
import { Subscription } from 'rxjs';
import { ConstantsService } from '../../../services/constants.service';
import { TranslateService } from '@ngx-translate/core';
import { requiredTrueValidator } from '../../shared/required-true-validator';
import { FormlyFileFieldState } from '../../shared/components/re-file-field/re-file-field.models';

import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';

import { IframeService } from '../../../services/iframe.service';
import { DialogsService } from '../../shared/dialogs.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-step-four',
  templateUrl: './step-four.component.html',
  styleUrls: ['./step-four.component.scss'],
})
export class StepFourComponent extends StepWithPacksDirective implements OnInit {

  public get now(): Date {
    return new Date();
  }

  constructor(
    private fb: UntypedFormBuilder,
    private filesService: FilesService,
    private apiService: ApiService,
    public constants: ConstantsService,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: string,
    private dialogs: DialogsService,
    private iframeService: IframeService,
    private errorHandler: ErrorHandler,
  ) {
    super();
    this.buildForm();
    this.buildHousingQualities();
  }

  public isSaving: boolean;
  public form: UntypedFormGroup;

  @ViewChildren(ReFileFieldComponent)
  public fileFields: QueryList<ReFileFieldComponent>;

  private hotSubscriptions: Subscription[] = [];

  public shouldDisplayCaptcha: boolean;

  private loadingDialog: MatDialogRef<any>;

  public housingQualities: { value: string, label: string }[];

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.constants.isDebug) {
      this.form.patchValue({
        firstName: 'Test1',
        lastName: 'Test2',
        cnp: '12345678901234',
        email: 'test@tdrs.ro',
        phoneNumber: '1234567890',
      });
    }
  }

  public onEnter(): void {
    super.onEnter();
    this.clearSubscriptions();

    this.form.patchValue({
      firstName: this.globalModel.step4.firstName,
      lastName: this.globalModel.step4.lastName,
      email: this.globalModel.step4.email,
    });

  }

  private clearSubscriptions(): void {
    if (this.hotSubscriptions.length) {
      for (const hotSubscription of this.hotSubscriptions) {
        hotSubscription.unsubscribe();
      }
      this.hotSubscriptions = [];
    }
  }

  public isInvalid(formControlName: string): boolean {
    const control = this.form.controls[formControlName];
    return control && !control.valid && control.touched;
  }

  public isInvalidWithError(formControlName: string, error: string): boolean {
    const control = this.form.controls[formControlName];
    if (!(control && !control.valid && control.touched)) {
      return false;
    }
    return control.hasError(error);
  }

  public async submit(): Promise<void> {

    this.closeLoadingDialog();

    if (!await this.isValid()) {
      return;
    }

    this.loadingDialog = this.dialogs.openLoadingDialog('Cererea ta se înregistrează. Te rugăm să aștepți.');

    this.isSaving = true;
    try {
      await this.apiService.buildSessionAndSave(this.globalModel);

      let fileFieldsToWait = 0;

      this.clearSubscriptions();
      await new Promise<void>((resolve, reject) => {
        let rejected = false;
        for (const fileField of this.fileFields) {
          const cnt = fileField.doUpload();
          if (cnt > 0) {
            this.hotSubscriptions.push(fileField.fieldConfigValue.stateChanged.subscribe(state => {
              if (state === 'uploaded' && !rejected) {
                fileFieldsToWait--;
                if (fileFieldsToWait === 0) {
                  resolve();
                }
              } else if (state === 'error' && !rejected) {
                rejected = true;
                reject('files-error');
              }
            }));
            fileFieldsToWait++;
          }
        }

        if (fileFieldsToWait === 0) {
          resolve();
        }
      });
      const doRedirect = () => {
        this.resetToFirst.emit();
        this.form.reset();
        this.iframeService.sendMessageToParent({redirect: '/multumim/'});
        setTimeout(() => {
          window.location.assign('https://restartenergy.ro/multumim/');
        }, 2000);
      };
      if (!await this.isValid()) {
        this.isSaving = false;
        this.closeLoadingDialog();
        return;
      }
      await this.apiService.finalizeSession(this.globalModel.step4.captcha);
      this.dialogs.alertDialog({data: {html: await this.trans('all-done'), title: 'Success!'}})
        .afterClosed().subscribe(() => {
        doRedirect();
      });
      if (!this.constants.isDebug) {
        setTimeout(() => {
          doRedirect();
        }, 4000);
      }
    } catch (e) {
      console.log(e);
      let shouldReload = false;
      this.errorHandler.handleError(e);
      let msgError = 'errors.unknown-error';
      if (e === 'files-error') {
        msgError = 'errors.file-upload-errors';
      }
      if (e?.error?.error === 'session-already-finalized' || e?.error?.error === 'invalid-session') {
        this.apiService.clearSession();
        msgError = 'errors.' + e?.error?.error;
        shouldReload = true;
      }
      if (e?.error?.error === 'invalid-captcha') {
        msgError = 'errors.invalid-captcha';
        this.form.controls.captcha?.reset();
      }
      msgError = await this.trans(msgError);
      const alertDialog = this.dialogs.alertDialog({data: {html: msgError}});
      if (shouldReload) {
        alertDialog.afterClosed().subscribe(() => {
          window.location.reload();
        });
      }
    }
    this.isSaving = false;
    this.closeLoadingDialog();
  }

  private closeLoadingDialog(): void {
    if (this.loadingDialog) {
      this.loadingDialog.close();
      this.loadingDialog = null;
    }
  }

  private async isValid(): Promise<boolean> {
    const errors: string[] = [];
    let isValid = true;
    const missingDocs: string[] = [];
    const anchors: string[] = [];
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      isValid = false;
      errors.push(await this.trans('errors.invalid-form-recheck-fields'));
      anchors.push(!this.globalModel.step4.acceptDataProcessing ? 'accept-data-processing' :
        !this.globalModel.step4.captcha ? 'step-four-captcha' : 'firstName-input');
    }
    if (!this.isFileFieldValid('identity_card')) {
      missingDocs.push(await this.trans('step4.id-card'));
      anchors.push('id-card');
    }
    if (this.hasEnergy && !this.isFileFieldValid('energy_invoice')) {
      missingDocs.push(await this.trans('step4.energy-bill'));
      anchors.push('energy-bill');
    }
    if (this.hasGas && !this.isFileFieldValid('gas_invoice')) {
      missingDocs.push(await this.trans('step4.gas-bill'));
      anchors.push('gas-bill');
    }
    if (missingDocs.length) {
      isValid = false;
      errors.push(await this.trans('errors.required-documents') + ': ' + missingDocs.join(', '));
    }

    // if ((this.hasEnergy || !this.globalModel.step4.acceptPropertyDocumentDeclaration) && !this.isFileFieldValid('property_document')) {
    //   isValid = false;
    //   errors.push(await this.trans('errors.property-document-' + (this.hasEnergy ? '' : 'or-declaration-') + 'required'));
    //   anchors.push('property-document');
    // }

    // if (!this.globalModel.step4.acceptPropertyDocumentDeclaration) {
    //   isValid = false;
    //   errors.push(await this.trans('errors.property-declaration-required'));
    //   anchors.push('space-declaration');
    // }

    if (!this.globalModel.step4.captcha && this.shouldDisplayCaptcha) {
      isValid = false;
      errors.push(await this.trans('errors.captcha-required'));
      anchors.push('step-four-captcha');
    }

    if (!isValid) {
      const dialog = this.dialogs.alertDialog({data: {html: '<ul>' + errors.map(err => `<li>${err}</li>`).join('') + '</ul>'}});
      if (anchors.length) {
        dialog.afterClosed().toPromise().then(() => {
          smoothScrollIntoView(document.getElementById(anchors[0]), {behavior: 'smooth', scrollMode: 'if-needed'});
        });
      }
    }
    return isValid;
  }

  private async trans(key: string): Promise<string> {
    return await this.translate.get(key).toPromise();
  }

  private getFileField(purpose: string): ReFileFieldComponent {
    return this.fileFields.find(ff => ff.fieldConfig?.purpose === purpose);
  }

  private getFileFieldState(purpose: string): FormlyFileFieldState {
    return this.getFileField(purpose)?.state;
  }

  private isFileFieldValid(purpose: string): boolean {
    const state = this.getFileFieldState(purpose);
    return state === 'added' || state === 'uploaded';
  }

  private buildForm(): void {
    this.form = this.fb.group({
      firstName: [undefined, Validators.required],
      lastName: [undefined, Validators.required],
      // fullName: [undefined, Validators.required],
      cnp: [undefined, Validators.required],
      email: [undefined, [Validators.required, Validators.email]],
      phoneNumber: [undefined, [Validators.required, Validators.pattern(/[0-9+ -]{6,20}/)]],
      // acceptPropertyDocumentDeclaration: [false],
      // isDeclarDreptLocativ

      declaredHasLocationRight: [false, [requiredTrueValidator()]],
      declaredNoLocationLitigation: [false, [requiredTrueValidator()]],
      housingQualityOfApplicant: [undefined, [Validators.required]],
      housingQualityOfApplicantOther: [undefined, [Validators.required]],

      acceptDataProcessing: [false, requiredTrueValidator()],
      acceptProviderRightToAccessNetworkOperatorDatabase: [false, requiredTrueValidator()],

      subscribeRestartEnergyPromotions: [false],
      // subscribeRestartEnergyPartnersPromotions: [false],
      acceptMarketSurveys: [false],
      acceptCustomerSatisfactionVerificationCampaigns: [false],

      voucherCode: [undefined],
      captcha: [undefined, [Validators.required]],
    });
    this.form.controls.acceptDataProcessing.valueChanges.subscribe(value => {
      if (value) {
        this.shouldDisplayCaptcha = true;
      }
    });
    this.form.controls.housingQualityOfApplicant.valueChanges.subscribe(value => {
      const control = this.form.controls.housingQualityOfApplicantOther;
      if (value === 'other') {
        if (!control.hasValidator(Validators.required)) {
          control.addValidators(Validators.required);
          control.updateValueAndValidity();
        }
      } else {
        if (control.hasValidator(Validators.required)) {
          control.clearValidators();
        }
        control.setValue(undefined);
        control.updateValueAndValidity();
      }
    });
    this.form.valueChanges.subscribe(() => {
      this.globalModel.step4 = this.form.value;
    });
  }

  private buildHousingQualities(): void {
    this.housingQualities = [
      {value: 'proprietar/coproprietar', label: 'proprietary-coproprietary'},
      {value: 'unic mostenitor', label: 'sole-heir'},
      {value: 'comostenitor', label: 'co-heir'},
      {value: 'sot', label: 'spouse'},
      {value: 'chirias', label: 'tenant'},
      {value: 'beneficiar renta viagera', label: 'life-annuity-beneficiary'},
      {value: 'other', label: 'other'},
    ];
  }
}

