<div class="row">
  <div class="col-12 offset-sm-1 col-sm-10 offset-md-2 col-md-8">
    <div class="h1 text-center pb-5" [innerHTML]="'step1.lets-change-provider-together' | translate"></div>
    <div class="card">
      <div class="header">
        {{ 'step1.begin-with-personalized-offer' | translate}}
      </div>
      <div class="content">
        <form [formGroup]="form" class="re-form">

          <div class="form-group">
            <re-locality-search-input
              formControlName="locality"
              [label]="'step1.form.your-locality' | translate"
              [placeholder]="'step1.form.your-locality' | translate"
            ></re-locality-search-input>
          </div>

          <label>{{'step1.form.what-bill-do-you-want-to-reduce' | translate}}</label>
          <div class="offer-types-container mb-4">
            <div class="me-1 me-md-2" tabindex="0" (click)="offerTypeClick('energy')"
                 (keyup.Space)="offerTypeClick('energy')"
                 [ngClass]="{active: offerTypeActive('energy')}">
              <ng-container
                *ngTemplateOutlet="offerType, context: {image: 'assets/icons/energy-2.svg', text: energyText}">
              </ng-container>
              <ng-template #energyText>
                <span class="offer-type-text" [innerHTML]="'step1.form.electricityFormatted' | translate"></span>
              </ng-template>
            </div>
            <div class="mx-1 mx-md-2" tabindex="0" (click)="offerTypeClick('gas')"
                 (keyup.Space)="offerTypeClick('gas')"
                 [ngClass]="{active: offerTypeActive('gas'), disabled: constants.gasDisabled}"
                 [hidden]="!selectedLocalityHasGas">
              <ng-container
                *ngTemplateOutlet="offerType, context: {image: 'assets/icons/gas-2.svg', text: gasText}">
              </ng-container>
              <ng-template #gasText>
                <span class="offer-type-text" [innerHTML]="'step1.form.gasFormatted' | translate"></span>
              </ng-template>
            </div>
            <div class="ms-1 ms-md-2" tabindex="0" (click)="offerTypeClick('both')"
                 (keyup.Space)="offerTypeClick('both')"
                 [ngClass]="{active: offerTypeActive('both'), disabled: constants.gasDisabled}"
                 [hidden]="!selectedLocalityHasGas">
              <ng-container
                *ngTemplateOutlet="offerType, context: {image: 'assets/icons/energy-gas.svg', text: energyGasText}">
              </ng-container>
              <ng-template #energyGasText>
                <span class="offer-type-text" [innerHTML]="'step1.form.bothGasElectricityFormatted' | translate"></span>
              </ng-template>
            </div>
          </div>
          <div class="consumptions-container" formGroupName="consumptions">
            <div class="mb-3 red" *ngIf="consumptionSelectVisibility.optionUnavailable">Această opțiune nu este disponibilă
              momentan.
            </div>
            <div class="mb-3" *ngIf="consumptionSelectVisibility.energy || consumptionSelectVisibility.gas">Alege cu
              aproximație consumul lunar
            </div>
            <div class="row">
              <div class="col-12 col-sm-6 ">
                <div class="form-group me-lg-3"
                     [ngClass]="{'custom-invisible': !consumptionSelectVisibility.energy}">
                  <re-select-input
                    label="Energie electrică"
                    formControlName="energy"
                    placeholder="Alege"
                    [options]="energyConsumptions"
                    [optionLabelFormatter]="consOptionLabelFormatter"
                    [inputFormatter]="selectInputFormatter"
                    [valueFormatter]="consValueFormatter"
                  ></re-select-input>
                </div>
              </div>

              <div class="col-12 col-sm-6">
                <div class="form-group ms-lg-3"
                     [ngClass]="{'custom-invisible': !consumptionSelectVisibility.gas}">
                  <re-select-input
                    label="Gaz natural"
                    formControlName="gas"
                    placeholder="Alege"
                    [options]="gasConsumptions"
                    [optionLabelFormatter]="consOptionLabelFormatter"
                    [inputFormatter]="selectInputFormatter"
                    [valueFormatter]="consValueFormatter"
                  ></re-select-input>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button type="button" class="re-button re-button-sm" (click)="nextStep()" [disabled]="!form.valid || consumptionSelectVisibility.optionUnavailable">
                {{'continue-calc-offer' | translate}}
                <svg-icon name="left-arrow"
                          [svgStyle]="{'width.px': '18', 'height.px': '18', 'transform': 'scaleX(-1)'}"></svg-icon>
              </button>
            </div>
          </div>
        </form>
        <!--        <div>-->
        <!--          <pre>{{form.value | json}}</pre>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</div>

<ng-template #offerType let-image="image" let-text="text">
  <div class="icon-wrapper">
    <div>
      <img [src]="image" alt=" "/>
    </div>
  </div>
  <div class="text-1 text-center pt-2">
    <ng-container *ngTemplateOutlet="text"></ng-container>
  </div>
  <div class="check">
    <svg-icon name="check" [svgStyle]="{'width': '100%', 'height': '100%'}"></svg-icon>
  </div>
</ng-template>
