import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { SvgHttpLoader, SvgLoader } from 'angular-svg-icon';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class SvgBrowserLoader implements SvgLoader {
  constructor(
    private transferState: TransferState,
    private http: HttpClient,
  ) {
  }

  public getSvg(url: string): Observable<string> {
    const key: StateKey<string> = makeStateKey<string>('transfer-svg:' + url);
    const data = this.transferState.get(key, null);
    if (data) {
      return new Observable(observer => {
        observer.next(data);
        observer.complete();
      });
    } else {
      return new SvgHttpLoader(this.http).getSvg(url);
    }
  }
}


export function svgBrowserLoaderFactory(http: HttpClient, transferState: TransferState): SvgLoader {
  return new SvgBrowserLoader(transferState, http);
}
