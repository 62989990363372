import { Component, ErrorHandler, HostListener, OnInit } from '@angular/core';
import { StepBaseDirective } from '../step-base.directive';
import PackModel from '../../../packs/models/pack.model';
import { PacksService } from '../../../packs/packs.service';
import { ConstantsService } from '../../../services/constants.service';
import { DialogsService } from '../../shared/dialogs.service';

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss'],
})
export class StepTwoComponent extends StepBaseDirective implements OnInit {

  public energyPacks: PackModel[] = [];
  public gasPacks: PackModel[] = [];

  private selectedPacks: { gasPack?: PackModel, energyPack?: PackModel } = {};

  public energyRowsCount: number;
  public gasRowsCount: number;

  public canSelectBoth: boolean;

  private loadingPrices: boolean;

  public showAllEnergyFeatures: boolean;
  public showAllGasFeatures: boolean;

  public isMobile: boolean;

  constructor(
    private packsService: PacksService,
    private constants: ConstantsService,
    private dialogs: DialogsService,
    private errorHandler: ErrorHandler,
  ) {
    super();
    this.onResize();
    this.showAllEnergyFeatures = this.showAllGasFeatures = !this.isMobile;
  }

  public async ngOnInit(): Promise<void> {
    this.calcRowCount();
    if (this.constants.isDebug) {
      this.onEnter();
    }
  }

  public calcRowCount(): void {
    this.energyRowsCount = 3 + 1 + (this.showAllEnergyFeatures ? this.packsService.getFeaturesCount('energy') : 3);
    this.gasRowsCount = 3 + 1 + (this.showAllGasFeatures ? this.packsService.getFeaturesCount('gas') : 3);
  }

  public onEnter(): void {
    if (this.globalModel.step2) {
      this.selectedPacks = this.globalModel.step2;
    } else {
      this.globalModel.step2 = this.selectedPacks;
    }
    if (!this.globalModel.step1 || !this.globalModel.step1.locality) {
      setTimeout(() => {
        this.onEnter();
      }, 500);
      return;
    }

    this.canSelectBoth = !this.constants.gasDisabled && this.globalModel.step1.offerType !== 'both'
      && (this.globalModel.step1.offerType !== 'energy' || this.globalModel.step1.locality.hasGas);

    this.energyPacks = this.globalModel.step1.offerType !== 'gas' ? this.packsService.getForEnergy() : [];
    this.gasPacks = this.globalModel.step1.offerType !== 'energy' ? this.packsService.getForGas() : [];
    if (this.selectedPacks.energyPack && this.energyPacks.length) {
      this.selectedPacks.energyPack = this.energyPacks.find(p => p.id === this.selectedPacks.energyPack.id);
      if (this.selectedPacks.energyPack) {
        this.selectedPacks.energyPack.selected = true;
      }
    }
    if (this.selectedPacks.gasPack && this.gasPacks.length) {
      this.selectedPacks.gasPack = this.gasPacks.find(p => p.id === this.selectedPacks.gasPack.id);
      if (this.selectedPacks.gasPack) {
        this.selectedPacks.gasPack.selected = true;
      }
    }
    this.loadingPrices = true;
    this.packsService.getPrices(this.energyPacks, this.gasPacks, this.globalModel).then(() => {
      this.loadingPrices = false;
      if(this.energyPacks.length == 1) {
        this.energyPackSelected(this.energyPacks[0]);
      }
    }).catch(e => {
      if (e?.error?.error) {
        console.error('get offer error', e.error.error);
      }
      this.errorHandler.handleError(e);
      this.dialogs.showUnknownErrorOccurred().then(dialogRef => {
        dialogRef.afterClosed().toPromise().then(() => {
          this.goBack();
        });
      });
    });

  }

  public get energyPacksCss(): { [key: string]: string } {
    return {
      'grid-template-rows': `repeat(${this.energyRowsCount}, auto)`,
      'grid-template-columns': (this.isMobile && !this.showAllEnergyFeatures ? 2 : 3) + `fr repeat(${Math.max(this.energyPacks.length, this.gasPacks.length)}, 2fr)`,
    };
  }

  public get gasPacksCss(): { [key: string]: string } {
    return {
      'grid-template-rows': `repeat(${this.gasRowsCount}, auto)`,
      'grid-template-columns': (this.isMobile && !this.showAllGasFeatures ? 2 : 3) + `fr repeat(${Math.max(this.energyPacks.length, this.gasPacks.length)}, 2fr)`,
    };
  }

  public get stepValid(): boolean {
    return !this.loadingPrices && (this.energyPacks.length === 0 || !!this.selectedPacks.energyPack)
      && (this.gasPacks.length === 0 || !!this.selectedPacks.gasPack);
  }

  public energyPackSelected(pack: PackModel): void {
    for (const energyPack of this.energyPacks) {
      energyPack.selected = energyPack === pack;
      energyPack.hovered = false;
    }
    this.selectedPacks.energyPack = pack;
  }

  public gasPackSelected(pack: PackModel): void {
    for (const gasPack of this.gasPacks) {
      gasPack.selected = gasPack === pack;
      gasPack.hovered = false;
    }
    this.selectedPacks.gasPack = pack;
  }

  public goBackSelectBoth(): void {
    this.globalModel.step1.offerType = 'both';
    this.goBack();
  }

  @HostListener('window:resize', ['$event']) // for window scroll events
  public onResize(): void {
    if (!this.constants.isBrowser) {
      return;
    }
    if (window.innerWidth <= 767 !== this.isMobile) {
      this.isMobile = window.innerWidth <= 767;
    }
  }
}
