/* eslint-disable quote-props */
import { Component, OnInit } from '@angular/core';
import { StepBaseDirective } from '../step-base.directive';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ConsumptionsService } from '../../../services/consumptions.service';
import { LocalityModel } from '../../../models/locality.model';
import { ConsumptionModel, EnergyConsumptionModel, GasConsumptionModel } from '../../../models/consumptions-model';
import { ConstantsService } from '../../../services/constants.service';

export function requiredConsumptionIfNeeded(key: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.parent && control.parent.parent) {
      const offerType = control.parent.parent.value.offerType;
      if (offerType !== 'both' && offerType !== key) {
        return null;
      }
    }
    const invalid = !control.value;
    return invalid ? {required: true} : null;
  };
}

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss'],
})
export class StepOneComponent extends StepBaseDirective implements OnInit {

  public form: UntypedFormGroup;
  public consumptionsForm: UntypedFormGroup;

  public get value(): any {
    return this.form.value;
  }

  public energyConsumptions: EnergyConsumptionModel[] = [];
  public gasConsumptions: GasConsumptionModel[] = [];

  public selectedLocalityModel: Partial<LocalityModel> = {hasGas: true};

  public consumptionSelectVisibility: { energy?: boolean, gas?: boolean, optionUnavailable?: boolean } = {};

  public get selectedLocalityHasGas(): boolean {
    return this.selectedLocalityModel?.hasGas;
  }

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly consService: ConsumptionsService,
    public readonly constants: ConstantsService,
  ) {
    super();
    this.buildForm();
  }

  public offerTypeClick(type: string): void {
    this.form.controls.offerType.setValue(type);
  }

  public async ngOnInit(): Promise<void> {
    this.energyConsumptions = await this.consService.getForEnergy();
    this.gasConsumptions = await this.consService.getForGas();
    if (this.constants.isDebug) {
      this.form.patchValue({
        locality: {
          id: 'c639f278-d313-4d08-8c29-f04403f9cc58',
          county: 'Timiș',
          locality: 'Timișoara',
          countyS: 'TIMIS',
          localityS: 'TIMISOARA',
          hasGas: true,
        },
        offerType: 'both',
        consumptions: {
          energy: 100,
          gas: 1,
        },
      });
    }
  }

  public onEnter(): void {
    if (this.globalModel.step1 && this.form.value !== this.globalModel.step1.offerType && this.globalModel.step1.offerType === 'both') {
      this.form.patchValue({offerType: 'both'});
    }
  }

  public offerTypeActive(offerType: string): boolean {
    return this.form.value.offerType === offerType;
  }

  public consOptionLabelFormatter(option: ConsumptionModel): any {
    return option.optionLabel;
  }

  public selectInputFormatter(option: ConsumptionModel): any {
    return option.inputFormatted;
  }

  public consValueFormatter(option: ConsumptionModel): any {
    return option.consumptionValue;
  }

  public nextStep(): void {
    this.goNext();
  }

  public get energyValid(): boolean {
    return (this.form.controls.consumptions as UntypedFormGroup).controls.energy.valid;
  }

  private buildForm(): void {
    this.form = this.fb.group({
        locality: ['', Validators.required],
        offerType: ['', Validators.required],
        consumptions: this.consumptionsForm = this.fb.group({
          energy: ['', requiredConsumptionIfNeeded('energy')],
          gas: ['', requiredConsumptionIfNeeded('gas')],
        }),
      },
    );
    this.form.controls.locality.valueChanges.subscribe(loc => {
      if (loc && typeof loc !== 'string') {
        this.selectedLocalityModel = loc;
        if (!loc.hasGas || this.constants.gasDisabled) {
          this.form.patchValue({offerType: 'energy'});
        } else {
          this.form.patchValue({offerType: 'both'});
        }
      }
    });
    this.form.controls.offerType.valueChanges.subscribe(offerType => {
      setTimeout(() => {
        this.consumptionsForm.controls.energy.updateValueAndValidity();
        this.consumptionsForm.controls.gas.updateValueAndValidity();
      });
      this.consumptionSelectVisibility.energy = (!this.constants.gasDisabled && offerType === 'both') || offerType === 'energy';
      this.consumptionSelectVisibility.gas = !this.constants.gasDisabled && offerType !== 'energy';
      this.consumptionSelectVisibility.optionUnavailable = this.constants.gasDisabled && !this.consumptionSelectVisibility.energy;
    });
    this.form.valueChanges.subscribe(() => {
      this.globalModel.step1 = this.form.value;
    });
  }
}
