<div class="steps-wrapper">
  <div class="bar-wrapper">
    <div class="bar">
      <div class="bar-inner" [ngStyle]="{width: progressPercent + '%'}">
      </div>
    </div>
  </div>
  <ng-container *ngFor="let step of steps">
    <div class="step" [ngClass]="{done: currentStep >= step}" (click)="stepClick(step)">
      <svg-icon name="progress-step" [svgStyle]="{'width.px': '44', 'height.px': '44'}"></svg-icon>
      <span>{{step}}</span>
    </div>
  </ng-container>
</div>
