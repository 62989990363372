import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-caret',
  template: `
    <svg-icon [name]="iconName" [svgStyle]="{'max-width': '100%', 'max-height': '100%'}" [ngStyle]="{width: width, height: height}"></svg-icon>
  `,
  styleUrls: ['./caret.component.scss'],
})
export class CaretComponent {
  @Input()
  public iconName = 'caret';
  @Input()
  public width = '15px';
  @Input()
  public height = '15px';
}
