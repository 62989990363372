import { Component, ErrorHandler, forwardRef } from '@angular/core';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { LocalityModel } from '../../../../models/locality.model';
import { LocalitiesService } from '../../../../services/localities.service';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';
import { ReBaseInputDirective } from '../re-base-input.directive';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

export function validLocalityValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const invalid = !control.value || typeof control.value !== 'object';
    return invalid ? {requiredSelect: {value: control.value}} : null;
  };
}

@Component({
  selector: 're-locality-search-input',
  templateUrl: './locality-search-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocalitySearchInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LocalitySearchInputComponent),
      multi: true,
    },
  ],
})
export class LocalitySearchInputComponent extends ReBaseInputDirective<LocalityModel> {

  public id: number = Math.round(Math.random() * 100000);

  public searching: boolean;
  public searchFailed: boolean;

  public model: any;

  constructor(
    private localitiesService: LocalitiesService,
    private errorHandler: ErrorHandler,
  ) {
    super();
  }

  public formatter = (x: LocalityModel) => x ? `${x.locality}, ${x.county}` : '';

  public searchLocalities: OperatorFunction<string, readonly LocalityModel[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.writeValue(null);
        this.onTouched(null);
        this.searching = true;
      }),
      filter(term => term && term.length > 3),
      switchMap(term => this.localitiesService.search(term)
        .pipe(tap(() => this.searchFailed = false),
          catchError((e) => {
            this.searchFailed = true;
            this.errorHandler.handleError(e);
            return of([]);
          }))),
      tap(() => this.searching = false),
    );


  public selectItem($event: NgbTypeaheadSelectItemEvent<any>): void {
    this.writeValue($event.item);
  }

  protected onChangeHook = (value: LocalityModel) => {
    if (value !== this.model && (value || this.model)) {
      this.model = value;
    }
  };
}
