import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LocalityModel } from '../models/locality.model';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root',
})
export class LocalitiesService {
  private url: string;

  constructor(
    private http: HttpClient,
    private constants: ConstantsService,
  ) {
    this.url = `${constants.apiUrl}LocalitiesApi/Search`;
  }

  public search(term: string): Observable<LocalityModel[]> {
    if (term === '' || !term) {
      return of([]);
    }
    return this.http.get<LocalityModel[]>(this.url, {params: {model: term}});
  }
}
