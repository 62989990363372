import { Injectable } from '@angular/core';
import PackModel, { FeatureModel } from './models/pack.model';
import GlobalModel from '../models/global-model';
import { ConstantsService } from '../services/constants.service';
import { HttpClient } from '@angular/common/http';
import { OfferRequestModel, OfferValuesModel } from './models/offer-values.model';
import { roundN } from '../services/utils';
import PackExtraOptionModel from './models/pack-extra-option.model';

@Injectable({
  providedIn: 'root',
})
export class PacksService {

  constructor(
    private constants: ConstantsService,
    private http: HttpClient,
  ) {
  }

  public getForEnergy(): PackModel[] {
    const list: Partial<PackModel>[] = [];

    list.push({
      id: 'energy-fix',
      title: 'energy-fix-formatted',
      description: 'energy-fix-description',
      class: 'yellow',
      features: {description: 'fix-sum'},
      priceType: 'month',
      isSubscription: true,
    });
    list.push({
      id: 'energy-go',
      title: 'energy-go-formatted',
      description: 'energy-go-description',
      class: 'green',
      features: {description: 'pay-what-you-consume'},
      priceType: 'unit',
      // extraDailyPrice: 35.7,
    });
    list.push({
      id: 'energy-go-online',
      title: 'energy-go-online-formatted',
      description: 'energy-go-online-description',
      class: 'yellow',
      features: {description: 'only-electronic-bill-energy'},
      priceType: 'unit',
      // extraDailyPrice: 35.7,
    });
    for (const pack of list) {
      pack.type = 'energy';
      pack.unit = 'kWh';
    }
    this.putFeatures(list);
    return list as PackModel[];
  }

  public getForGas(): PackModel[] {
    const list: Partial<PackModel>[] = [];

    list.push({
      id: 'gas-fix',
      title: 'gas-go-formatted',
      description: 'gas-go-description',
      class: 'green',
      features: {description: 'fix-sum'},
      priceType: 'month',
      isSubscription: true,
    });
    list.push({
      id: 'gas-go',
      title: 'gas-fix-formatted',
      description: 'gas-fix-description',
      class: 'yellow',
      features: {description: 'pay-what-you-consume'},
      priceType: 'unit',
    });
    list.push({
      id: 'gas-go-online',
      title: 'gas-safe-formatted',
      description: 'gas-safe-description',
      class: 'green',
      features: {description: 'only-electronic-bill-gas'},
      priceType: 'unit',
    });

    for (const pack of list) {
      pack.type = 'gas';
      pack.unit = 'MWh';
    }

    this.putFeatures(list);
    return list as PackModel[];
  }

  private putFeatures(packs: Partial<PackModel>[]): void {
    const features = this.getFeaturesIds();
    for (const pack of packs) {
      for (const feature of features) {
        if (feature.type === 'boolean') {
          pack.features[feature.key] = true; // Math.random() < 0.5;
        }
      }
      pack.features['contract-duration'] = '3-month';
      pack.features['contract-dissolution'] = 'free';

      if (pack.type === 'energy') {
        pack.extraOptions = [
          // {id: 'energy-safe-extra-option', hourPrice: 0.04, monthPrice: roundN(0.04 * 24 * 30 * 1.19)},
        ];
      } else {
        pack.extraOptions = [
          // {id: 'gas-safe-extra-option', hourPrice: 0.02, monthPrice: roundN(0.02 * 24 * 30 * 1.19)},
          // {id: 'gas-safe-plus-extra-option', hourPrice: 0.04, monthPrice: roundN(0.04 * 24 * 30 * 1.19)},
        ];
      }
      pack.extraOptionsState = {};
    }
  }

  public async getPrices(energyPacks: PackModel[], gasPacks: PackModel[], globalStep: GlobalModel): Promise<void> {
    const hasEnergy = globalStep.step1.offerType !== 'gas' && globalStep.step1.consumptions.energy;
    const hasGas = globalStep.step1.offerType !== 'energy' && globalStep.step1.consumptions.gas;

    for (const pack of [...energyPacks, ...gasPacks]) {
      pack.priceLoading = true;
    }

    const requestData: OfferRequestModel = {
      localityId: globalStep.step1.locality.id,
      energyConsumption: globalStep.step1.consumptions.energy,
      gasConsumption: globalStep.step1.consumptions.gas,
      type: globalStep.step1.offerType,
      debugData: globalStep.step1.locality,
    };
    const offerValues = await this.getOfferValues(requestData);

    if (hasEnergy && offerValues.energy) {
      const packFix = energyPacks[0];
      packFix.monthlyPrice = offerValues.energy.energyFix;
      packFix.unitPrice = roundN(offerValues.energy.energyFixUnit, 2);

      const packGo = energyPacks[1];
      packGo.monthlyPrice = offerValues.energy.energyGo;
      packGo.unitPrice = roundN(offerValues.energy.energyGoUnit, 2);

      const packGoOnline = energyPacks[2];
      packGoOnline.monthlyPrice = offerValues.energy.energyGoOnline;
      packGoOnline.unitPrice = roundN(offerValues.energy.energyGoOnlineUnit, 2);

      packGoOnline.monthlyPriceX2 = offerValues.energy.energyGoOnlineX2;
      packGoOnline.unitPriceX2 = roundN(offerValues.energy.energyGoOnlineX2Unit, 2);
      energyPacks.splice(0, 1);
      energyPacks.splice(1, 1);
      energyPacks[0].selected = true;
    }

    if (hasGas && offerValues.gas) {
      const packFix = gasPacks[0];
      packFix.monthlyPrice = roundN(offerValues.gas.gasFix);
      packFix.unitPrice = roundN(offerValues.gas.gasFixUnit);

      const packGo = gasPacks[1];
      packGo.monthlyPrice = roundN(offerValues.gas.gasGo);
      packGo.unitPrice = roundN(offerValues.gas.gasGoUnit);

      const packGoOnline = gasPacks[2];
      packGoOnline.monthlyPrice = roundN(offerValues.gas.gasGoOnline);
      packGoOnline.unitPrice = roundN(offerValues.gas.gasGoOnlineUnit);

      packGoOnline.monthlyPriceX2 = roundN(offerValues.gas.gasGoOnlineX2);
      packGoOnline.unitPriceX2 = roundN(offerValues.gas.gasGoOnlineX2Unit);
    }

    for (const pack of [...energyPacks, ...gasPacks]) {
      pack.priceLoading = false;
    }
    globalStep.isExpired = offerValues.isExpired;
  }

  public async getOfferValues(data: OfferRequestModel): Promise<OfferValuesModel> {
    const url = `${this.constants.apiUrl}Offer/Get`;
    return await this.http.post<OfferValuesModel>(url, data).toPromise();
  }

  public getFeaturesIds(type?: string): FeatureModel[] {
    return this.features;
  }

  public getFeaturesCount(type?: string): number {
    return this.features.length;
  }

  public getPackSpecificId(id: string): string {
    if (!id) {
      return id;
    }
    return id.replace('gas-', '').replace('energy-', '');
  }

  public getSelectedExtraOptions(pack: PackModel): PackExtraOptionModel[] {
    const list = [];

    for (const extraOption of pack.extraOptions) {
      if (pack.extraOptionsState[extraOption.id]) {
        list.push(extraOption);
      }
    }

    return list;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private features: FeatureModel[] = [
    {key: 'description', type: 'string', noDesc: true},
    {key: 'contract-duration', type: 'string', noDesc: true},
    {key: 'contract-dissolution', type: 'string', noDesc: true},
    {key: 'online-bill-payment', type: 'boolean'},
    {key: 'personal-account', type: 'boolean'},
    {key: 'sign-contract-online', type: 'boolean'},
    {key: 'flexible-provider', type: 'boolean'},
    {key: '247-support', type: 'boolean'},
  ];
}
