<div class="mb-3"></div>
<div class="main-area row">
  <div class="left col-12 col-sm-8">
    <div class="card">
      <div class="header">
        {{'personal-data' | translate}}
      </div>
      <div class="content">
        <form class="re-form" [formGroup]="form">
          <div class="form-group">
            <label for="firstName-input">{{'firstName' | translate}}</label>
            <input id="firstName-input"
                   type="text"
                   class="form-control input"
                   formControlName="firstName"
                   [class.is-invalid]="isInvalid('firstName')"
                   [placeholder]="'firstName' | translate"/>
            <div class="invalid-feedback"
                 *ngIf="isInvalidWithError('firstName', 'required')">{{'field-required' | translate}}</div>
          </div>
          <div class="form-group">
            <label for="lastName-input">{{'lastName' | translate}}</label>
            <input id="lastName-input"
                   type="text"
                   class="form-control input"
                   formControlName="lastName"
                   [class.is-invalid]="isInvalid('lastName')"
                   [placeholder]="'lastName' | translate"/>
            <div class="invalid-feedback"
                 *ngIf="isInvalidWithError('lastName', 'required')">{{'field-required' | translate}}</div>
          </div>
          <div class="form-group">
            <label for="cnp-input">{{'cnp' | translate}}</label>
            <input id="cnp-input"
                   type="text"
                   class="form-control input"
                   formControlName="cnp"
                   [class.is-invalid]="isInvalid('cnp')"
                   [placeholder]="'cnp' | translate"/>
            <div class="invalid-feedback"
                 *ngIf="isInvalidWithError('cnp', 'required')">{{'field-required' | translate}}</div>
          </div>
          <div class="form-group">
            <label for="email-input">{{'email' | translate}}</label>
            <input id="email-input"
                   type="text"
                   class="form-control input"
                   formControlName="email"
                   [class.is-invalid]="isInvalid('email')"
                   [placeholder]="'email' | translate"/>
            <div class="invalid-feedback"
                 *ngIf="isInvalidWithError('email', 'required')">{{'field-required' | translate}}</div>
            <div class="invalid-feedback"
                 *ngIf="isInvalidWithError('email', 'email')">{{'invalid-email' | translate}}</div>
          </div>
          <div class="form-group">
            <label for="phone-input">{{'phone-number' | translate}}</label>
            <input id="phone-input"
                   type="text"
                   class="form-control input"
                   formControlName="phoneNumber"
                   [class.is-invalid]="isInvalid('phoneNumber')"
                   [placeholder]="'phone-number' | translate"/>
            <div class="invalid-feedback"
                 *ngIf="isInvalidWithError('phoneNumber', 'required')">{{'field-required' | translate}}</div>
            <div class="invalid-feedback"
                 *ngIf="isInvalidWithError('phoneNumber', 'pattern')">{{'invalid-phone-number' | translate}}</div>
          </div>
          <div class="form-group">
            <label for="voucher-code">{{'voucherCode' | translate}}</label>
            <input id="voucher-code"
                   type="text"
                   class="form-control input"
                   formControlName="voucherCode"
                   [class.is-invalid]="isInvalid('voucherCode')"
                   [placeholder]="'voucherCode' | translate"/>
          </div>
          <div class="green h2">{{'step4.files-upload' | translate}}</div>
          <div class="form-group" id="id-card">
            <div>{{'step4.id-card' | translate}}</div>
            <div class="text-gray" [innerHTML]="'step4.id-card-description' | translate"></div>
            <app-re-file-field
              [fieldConfig]="{purpose: 'identity_card'}"
            ></app-re-file-field>
          </div>
          <div class="form-group" *ngIf="hasEnergy" id="energy-bill">
            <div>{{'step4.energy-bill' | translate}}</div>
            <div class="text-gray" [innerHTML]="'step4.energy-bill-description' | translate"></div>
            <app-re-file-field
              [fieldConfig]="{purpose: 'energy_invoice'}"
            ></app-re-file-field>
          </div>
          <div class="form-group" *ngIf="hasGas" id="gas-bill">
            <div>{{'step4.gas-bill' | translate}}</div>
            <div class="text-gray" [innerHTML]="'step4.gas-bill-description' | translate"></div>
            <app-re-file-field
              [fieldConfig]="{purpose: 'gas_invoice'}"
            ></app-re-file-field>
          </div>
          <!--          <div class="form-group" id="property-document">-->
          <!--            <div>{{'step4.property-document' | translate}}</div>-->
          <!--            <div class="text-gray" [innerHTML]="'step4.property-document-description' | translate"></div>-->
          <!--            <app-re-file-field-->
          <!--              [fieldConfig]="{purpose: 'property_document'}"-->
          <!--            ></app-re-file-field>-->
          <!--          </div>-->

          <ng-container>
            <div class="green h2 mb-2" id="space-declaration">{{'step4.declaration-long' | translate}}</div>
            <div class="form-group">
              <app-re-checkbox formControlName="declaredHasLocationRight">
                <div class="d-flex align-items-center">
                  <div class="ps-2">{{'step4.accept-declaration-long' | translate}}</div>
                </div>
              </app-re-checkbox>
              <div class="invalid-feedback d-block"
                   *ngIf="isInvalidWithError('declaredHasLocationRight', 'requiredTrue')">{{'errors.required-true' | translate}}</div>
            </div>
            <div class="form-group" *ngIf="globalModel.step4.declaredHasLocationRight">
              <div class="form-group">
                <label for="housingQualityOfApplicant-input">{{'step4.housingQualityOfApplicant' | translate}}</label>
                <select id="housingQualityOfApplicant-input"
                        class="form-control input"
                        formControlName="housingQualityOfApplicant"
                        [class.is-invalid]="isInvalid('housingQualityOfApplicant')">
                  <option *ngFor="let hq of housingQualities"
                          [value]="hq.value">{{'step4.' + hq.label | translate}}</option>
                </select>
                <div class="invalid-feedback"
                     *ngIf="isInvalidWithError('housingQualityOfApplicant', 'required')">{{'field-required' | translate}}</div>
              </div>
            </div>
            <div class="form-group" *ngIf="globalModel.step4.housingQualityOfApplicant === 'other'">
              <label for="housingQualityOfApplicant-other-input">{{'step4.other' | translate}}</label>
              <input id="housingQualityOfApplicant-other-input"
                     type="text"
                     class="form-control input"
                     formControlName="housingQualityOfApplicantOther"
                     [class.is-invalid]="isInvalid('housingQualityOfApplicantOther')"
                     [placeholder]="'step4.housingQualityOfApplicant-other' | translate"/>
              <div class="invalid-feedback"
                   *ngIf="isInvalidWithError('housingQualityOfApplicantOther', 'required')">{{'field-required' | translate}}</div>
            </div>
            <div class="form-group"
                 *ngIf="globalModel.step4.housingQualityOfApplicant && (globalModel.step4.housingQualityOfApplicant !== 'other' || globalModel.step4.housingQualityOfApplicantOther)">
              <app-re-checkbox formControlName="declaredNoLocationLitigation">
                <div class="d-flex align-items-center">
                  <div class="ps-2">{{'step4.no-litigation-declarations' | translate}}</div>
                </div>
              </app-re-checkbox>
              <div class="invalid-feedback d-block"
                   *ngIf="isInvalidWithError('declaredNoLocationLitigation', 'requiredTrue')">{{'errors.required-true' | translate}}</div>
            </div>
          </ng-container>

          <!--          <ng-container>-->
          <!--            <div class="green h2" id="space-declaration-old">{{'step4.or' | translate}}</div>-->
          <!--            <div class="form-group">-->
          <!--              <div class="text-gray">{{'step4.space-declaration-text' | translate}}</div>-->
          <!--              <div class="border-gray my-3 p-3 p-sm-4 text-gray">-->
          <!--                <div class="text-center mb-2">{{'step4.declaration' | translate}},</div>-->
          <!--                <div class="text-center">{{'step4.declaration-text' | translate}}</div>-->
          <!--                <div class="d-flex justify-content-between mt-5">-->
          <!--                  <div>{{'step4.date' | translate}}, <br/> {{now | date:"dd/MM/yyyy"}}</div>-->
          <!--                  <div>{{'step4.name' | translate}},-->
          <!--                    <br/> {{form.controls.firstName.value + ' ' + form.controls.lastName.value}}</div>-->
          <!--                </div>-->
          <!--                <div class="d-flex justify-content-center">-->
          <!--                  <div class="mt-3 rounded-corners-lg border-gray p-3 d-inline-flex"-->
          <!--                       (click)="form.patchValue({'acceptPropertyDocumentDeclaration': !form.value['acceptPropertyDocumentDeclaration']})">-->
          <!--                    <app-re-checkbox-->
          <!--                      formControlName="acceptPropertyDocumentDeclaration">-->
          <!--                      <div class="d-flex align-items-center">-->
          <!--                        <div class="d-flex align-items-center">-->
          <!--                          <div class="ps-2 flex-grow-1 text-nowrap">{{'step4.accept-declaration' | translate}}</div>-->
          <!--                          <div class="extra-option-help ms-5"-->
          <!--                               [ngbPopover]="'step4.accept-declaration-help-text' | translate"-->
          <!--                               placement="bottom" triggers="mouseenter:mouseleave">?-->
          <!--                          </div>-->
          <!--                        </div>-->
          <!--                      </div>-->
          <!--                    </app-re-checkbox>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </ng-container>-->

          <div class="form-group mt-5">
            <app-re-checkbox
              formControlName="acceptProviderRightToAccessNetworkOperatorDatabase"
              id="accept-provider-right-to-access-network-operator-database">
              <div class="d-flex align-items-center">
                <div class="ps-2">{{'step4.accept-provider-right-to-access-network-operator-database' | translate}}</div>
              </div>
            </app-re-checkbox>
            <div class="invalid-feedback d-block"
                 *ngIf="isInvalidWithError('acceptProviderRightToAccessNetworkOperatorDatabase', 'requiredTrue')">{{'errors.required-true' | translate}}</div>
          </div>
          <div class="form-group mt-5">
            <app-re-checkbox
              formControlName="acceptDataProcessing"
              id="accept-data-processing">
              <div class="d-flex align-items-center">
                <div class="ps-2">{{'step4.accept-data-processing' | translate}}</div>
              </div>
            </app-re-checkbox>
            <div class="invalid-feedback d-block"
                 *ngIf="isInvalidWithError('acceptDataProcessing', 'requiredTrue')">{{'errors.required-true' | translate}}</div>
          </div>
          <div class="form-group">
            <app-re-checkbox
              formControlName="subscribeRestartEnergyPromotions">
              <div class="d-flex align-items-center">
                <div class="ps-2">{{'step4.accept-restartenergy-news' | translate}}</div>
              </div>
            </app-re-checkbox>
          </div>
          <div class="form-group">
            <app-re-checkbox
              formControlName="acceptMarketSurveys">
              <div class="d-flex align-items-center">
                <div class="ps-2">{{'step4.accept-market-surveys' | translate}}</div>
              </div>
            </app-re-checkbox>
          </div>
          <div class="form-group">
            <app-re-checkbox
              formControlName="acceptCustomerSatisfactionVerificationCampaigns">
              <div class="d-flex align-items-center">
                <div class="ps-2">{{'step4.accept-customer-satisfaction-verification-campaigns' | translate}}</div>
              </div>
            </app-re-checkbox>
          </div>
<!--          <div class="form-group">-->
<!--            <app-re-checkbox-->
<!--              formControlName="subscribeRestartEnergyPartnersPromotions">-->
<!--              <div class="d-flex align-items-center">-->
<!--                <div class="ps-2">{{'step4.accept-partners-news' | translate}}</div>-->
<!--              </div>-->
<!--            </app-re-checkbox>-->
<!--          </div>-->
          <div class="form-group">
            <span [innerHTML]="'step4.privacy-policy-details' | translate"></span>
          </div>
          <div class="form-group" id="step-four-captcha" *ngIf="shouldDisplayCaptcha">
            <re-captcha
              [siteKey]="constants.recaptchaSiteKey"
              formControlName="captcha"></re-captcha>
          </div>
        </form>

        <!--        <div>-->
        <!--          <pre>{{form.value | json}}</pre>-->
        <!--        </div>-->
        <div class="d-flex justify-content-between mt-3">
          <button class="re-button re-button-sm" (click)="goBack()">{{ 'prev-step' | translate }}</button>
          <button class="re-button re-button-sm" (click)="submit()">
            <ng-container *ngIf="!isSaving">{{'next' | translate}}
              <svg-icon name="left-arrow"
                        [svgStyle]="{'width.px': '18', 'height.px': '18', 'transform': 'scaleX(-1)'}"></svg-icon>
            </ng-container>
            <ng-container *ngIf="isSaving">
              <span>&nbsp;</span>
              <svg-icon name="spinner" class="icon"></svg-icon>
              <span>&nbsp;</span>
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="right col-12 col-sm-4 mt-4 mt-sm-0">
    <app-mini-cart [packs]="packs" [isX2]="isX2"></app-mini-cart>
  </div>
</div>
<div class="my-5"></div>
