import { Component, ErrorHandler, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../services/api.service';
import GlobalModel from '../../../../models/global-model';
import { TranslateService } from '@ngx-translate/core';
import { ConstantsService } from '../../../../services/constants.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.scss'],
})
export class SendEmailDialogComponent {

  public form: UntypedFormGroup;
  public isSending: boolean;

  constructor(
    public dialogRef: MatDialogRef<SendEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SendEmailDialogData,
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private trans: TranslateService,
    private errorHandler: ErrorHandler,
    public constants: ConstantsService,
  ) {
    this.form = fb.group({
      // fullName: [data.fullName, Validators.required],
      firstName: [data.firstName, Validators.required],
      lastName: [data.lastName, Validators.required],
      email: [data.email, [Validators.required, Validators.email]],
      captcha: [undefined, [Validators.required]],
    });
  }


  public async send(): Promise<void> {
    if (this.isSending || !this.form.valid) {
      return;
    }
    this.isSending = true;
    this.dialogRef.disableClose = true;
    try {
      await this.api.sendClientEmail(this.form.value, this.data.globalModel);
      this.dialogRef.close({code: 'alert-success', ...this.form.value});
    } catch (e) {
      console.log(e);
      let shouldReload = false;
      this.errorHandler.handleError(e);
      let code = 'alert-unknown-error';

      if (e?.error?.error === 'session-already-finalized') {
        this.api.clearSession();
        code = 'session-already-finalized';
        shouldReload = true;
      } else if (e?.error?.error === 'invalid-session') {
        this.api.clearSession();
        code = 'invalid-session';
        shouldReload = true;
      } else if (e?.error?.error === 'invalid-captcha') {
        code = 'invalid-captcha';
      }
      this.dialogRef.close({code, shouldReload, ...this.form.value});
    }
    this.dialogRef.disableClose = false;
    this.isSending = false;
  }

  public isInvalid(formControlName: string): boolean {
    const control = this.form.controls[formControlName];
    return control && !control.valid && control.touched;
  }

  public isInvalidWithError(formControlName: string, error: string): boolean {
    const control = this.form.controls[formControlName];
    if (!(control && !control.valid && control.touched)) {
      return false;
    }
    return control.hasError(error);
  }

  public throwSomeError(): void {
    throw new Error('some error manually thrown');
  }
}

export interface SendEmailDialogData {
  firstName: string;
  lastName: string;
  email: string;
  globalModel: GlobalModel;
}

export interface SendEmailDialogDataResult {
  // fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  code: string;
  shouldReload: boolean;
}
