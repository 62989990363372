<h1 mat-dialog-title>
  <ng-container *ngIf="data.title">{{data.title}}</ng-container>
  <ng-container *ngIf="!data.title">{{'warning' | translate}}!</ng-container>
</h1>
<div mat-dialog-content>
  <div [innerHTML]="data.html"></div>
</div>
<div mat-dialog-actions class="d-flex justify-content-end pb-4">
  <button [mat-dialog-close] cdkFocusInitial class="re-button-sm">Ok</button>
</div>
