import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaretComponent } from './components/caret/caret.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LocalitySearchInputComponent } from './components/locality-search-input/locality-search-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectInputComponent } from './components/select-input/select-input.component';
import { LanguagesBarComponent } from './components/languages-bar/languages-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReCheckboxComponent } from './components/re-checkbox/re-checkbox.component';
import { ReFileFieldComponent } from './components/re-file-field/re-file-field.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { SendEmailDialogComponent } from './components/send-email-dialog/send-email-dialog.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [
    CaretComponent,
    LocalitySearchInputComponent,
    SelectInputComponent,
    LanguagesBarComponent,
    ReCheckboxComponent,
    ReFileFieldComponent,
    AlertDialogComponent,
    SendEmailDialogComponent,
    LoadingDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    NgbTypeaheadModule,
    NgbDropdownModule,
    TranslateModule.forChild(),
    NgxFileDropModule,
    MatDialogModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  exports: [
    CaretComponent,
    LocalitySearchInputComponent,
    SelectInputComponent,
    LanguagesBarComponent,
    ReCheckboxComponent,
    ReFileFieldComponent,
    AlertDialogComponent,
    SendEmailDialogComponent,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
})
export class SharedModule {
}
