<h1 mat-dialog-title>{{'step3.send-email-modal-title' | translate}}</h1>
<div mat-dialog-content>
  <div class="mb-3">{{'step3.send-email-modal-description' | translate}}</div>
  <form class="re-form" [formGroup]="form">
    <div class="form-group">
      <label for="firstName-input">{{'firstName' | translate}}</label>
      <input id="firstName-input"
             type="text"
             class="form-control input"
             formControlName="firstName"
             [class.is-invalid]="isInvalid('firstName')"
             [placeholder]="'firstName' | translate"/>
      <div class="invalid-feedback"
           *ngIf="isInvalidWithError('firstName', 'required')">{{'field-required' | translate}}</div>
    </div>
    <div class="form-group">
      <label for="lastName-input">{{'lastName' | translate}}</label>
      <input id="lastName-input"
             type="text"
             class="form-control input"
             formControlName="lastName"
             [class.is-invalid]="isInvalid('lastName')"
             [placeholder]="'lastName' | translate"/>
      <div class="invalid-feedback"
           *ngIf="isInvalidWithError('lastName', 'required')">{{'field-required' | translate}}</div>
    </div>

    <div class="form-group">
      <label for="email-input">{{'email' | translate}}</label>
      <input id="email-input"
             type="text"
             class="form-control input"
             formControlName="email"
             [class.is-invalid]="isInvalid('email')"
             [placeholder]="'email' | translate"/>
      <div class="invalid-feedback"
           *ngIf="isInvalidWithError('email', 'required')">{{'field-required' | translate}}</div>
      <div class="invalid-feedback"
           *ngIf="isInvalidWithError('email', 'email')">{{'invalid-email' | translate}}</div>
    </div>
    <div class="form-group">
      <re-captcha
        [siteKey]="constants.recaptchaSiteKey"
        formControlName="captcha"></re-captcha>
    </div>
  </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-between pb-4">
  <button class="re-button re-button-sm gray" (click)="dialogRef.close()">{{'step3.cancel' | translate}}</button>
  <button class="re-button re-button-sm orange" (click)="throwSomeError()" [hidden]="true">throw an error</button>
  <button class="re-button-sm d-flex align-items-center" (click)="send()" cdkFocusInitial [disabled]="!form.valid">
    <span *ngIf="!isSending">{{'step3.send' | translate}}</span>
    <ng-container *ngIf="isSending">
      <span>&nbsp;</span>
      <svg-icon name="spinner" class="icon"></svg-icon>
      <span>&nbsp;</span>
    </ng-container>
  </button>
</div>
