import { Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReBaseInputDirective } from '../re-base-input.directive';

@Component({
  selector: 'app-re-checkbox',
  templateUrl: './re-checkbox.component.html',
  styleUrls: ['./re-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReCheckboxComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReCheckboxComponent),
      multi: true,
    },
  ],
})
export class ReCheckboxComponent extends ReBaseInputDirective<boolean> {

  public get getValue(): boolean {
    return this.value;
  }

  public toggle($event): void {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.writeValue(!this.value);
    this.onTouched(this.value);
  }
}
