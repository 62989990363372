import { EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgxFileDropEntry } from 'ngx-file-drop';

type Partial<T> = {
  [P in keyof T]?: T[P];
};

export interface FormlyFileFieldConfig {
  uploadUrl: string;
  deleteUrl: string;
  purpose: string;
  multiple?: boolean;
  accept?: string;
  acceptArr: string[];
  uploadTrigger?: EventEmitter<any>;
  stateChanged?: Observable<FormlyFileFieldState>;
  state?: FormlyFileFieldState;
  isValid?: boolean;
  required?: true;
}

export type PartialFormlyFileFieldConfig = Partial<FormlyFileFieldConfig>;


export interface ProcessedFile {
  droppedFile?: NgxFileDropEntry;
  systemFile?: File;
  backendFile?: { id: string, ownerToken?: string };
  state: FormlyFileFieldState;
  progress?: number;
  uploadSubscription?: Subscription;
  name?: string;
  errorReason?: string;
}

export type FormlyFileFieldState = 'empty' | 'added' | 'uploading' | 'uploaded' | 'error';


export const defaultFileFieldConfig: FormlyFileFieldConfig = {
  uploadUrl: 'files/upload',
  deleteUrl: 'files/delete',
  purpose: 'file',
  multiple: true,
  acceptArr: ['jpg', 'jpeg', 'png', 'pdf'],
  accept: ['jpg', 'jpeg', 'png', 'pdf'].join(', '),
};
