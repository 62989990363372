<div class="card">
  <div class="content p-small">
    <div class="green h2">{{'monthly-pay' | translate}}:</div>
    <ng-container *ngFor="let pack of packs">
      <ng-container *ngIf="pack">
        <div class="h2 pb-2 d-flex justify-content-between align-items-center">
          <div>
            <div>{{(pack.isSubscription ? 'subscription' : 'pack') | translate}}</div>
            <div [innerHTML]="'packs.' + pack.id | translate"></div>
            <div *ngIf="isX2" [innerHTML]="'packs.x2' | translate"></div>
          </div>
          <div>
            <svg-icon *ngIf="pack.priceLoading" name="spinner-green" class="icon"></svg-icon>
            <ng-container
              *ngIf="!pack.priceLoading">{{getMonthlyPriceForPack(pack)}} {{'ron' | translate}}</ng-container>
          </div>
        </div>
        <div *ngIf="pack.extraDailyPrice" class="d-flex justify-content-end fw-bold mb-3">
          + {{pack.extraDailyPrice * 30}} lei/lună abonament
        </div>
        <ng-container *ngFor="let eo of pack.extraOptions">
          <div *ngIf="pack.extraOptionsState[eo.id]" class="h3 pb-2 d-flex justify-content-between align-items-center">
            <div>
              <div [innerHTML]="'extra-options.' + eo.id | translate"></div>
            </div>
            <div>
              {{eo.monthPrice}} {{'ron' | translate}}
            </div>
          </div>
        </ng-container>
        <hr/>
      </ng-container>
    </ng-container>
    <div [innerHTML]="'cart-packs-price-explanation' | translate"></div>
    <div class="d-flex justify-content-between pt-3" *ngIf="packs && packs.length">
      <div class="h1">Total</div>
      <div>
        <div *ngIf="pricesLoading">
          <svg-icon name="spinner-green" class="icon"></svg-icon>
        </div>
        <div class="h1 fw-bold" *ngIf="!pricesLoading">
          {{total}} {{'ron' | translate}}
        </div>
        <div [innerHTML]="'vat-included' | translate"></div>
      </div>
    </div>
  </div>
</div>
