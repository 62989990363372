<ng-container *ngIf="!pack || pack.priceLoading">
  <span class="number">&nbsp;</span>
  <svg-icon name="spinner-green" class="icon icon-md"></svg-icon>
</ng-container>
<ng-container *ngIf="pack && !pack.priceLoading">
  <ng-container *ngIf="pack.priceType === 'month'">
    <span class="number">{{monthlyPrice}}</span>
    <span class="unit" [class.white]="whiteUnit">{{'ron' | translate}}/{{'month' | translate}}</span>
  </ng-container>
  <ng-container *ngIf="pack.priceType === 'unit'">
    <span class="number">
      <ng-container *ngIf="unitPrice >= 10">{{unitPrice}}</ng-container>
      <ng-container *ngIf="unitPrice < 10">{{unitPrice | number: '1.2-2'}}</ng-container>
    </span>
    <span class="unit" [class.white]="whiteUnit">{{'ron' | translate}}/{{pack.unit}}</span>
  </ng-container>
  <ng-container *ngIf="pack.extraDailyPrice">
    <div class="extra-daily-price fw-bold">+ {{pack.extraDailyPrice}} lei/zi abonament</div>
  </ng-container>
</ng-container>
