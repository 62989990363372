<label [for]="'locality-input-' + id">{{label}}</label>
<div class="caret-owner"
     [class.is-invalid]="showAsInvalid">
  <input [id]="'locality-input-' + id" class="input form-control " type="text"
         [class.is-invalid]="showAsInvalid"
         [placeholder]="placeholder"
         [editable]="false"
         [(ngModel)]="model"
         tabindex="0"
         [ngbTypeahead]="searchLocalities"
         [resultTemplate]="localitiesSearchResultTemplate"
         [inputFormatter]="formatter"
         (selectItem)="selectItem($event)"
  />
  <app-caret iconName="spinner-red" width="30px" height="30px" [hidden]="!searching"></app-caret>
</div>
<div class="invalid-feedback" *ngIf="searchFailed">{{'step1.form.locality-search-error' | translate}}</div>
<ng-template #localitiesSearchResultTemplate let-r="result" let-t="term">
  <ngb-highlight [result]="formatter(r)" [term]="t.split(' ')"></ngb-highlight>
</ng-template>
