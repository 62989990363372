import { Injectable } from '@angular/core';
import GlobalModel from '../models/global-model';
import { PacksService } from '../packs/packs.service';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root',
})
export class DebugModelService {

  constructor(
    private packsService: PacksService,
    private constants: ConstantsService,
  ) {
  }

  public async init(globalModel: GlobalModel): Promise<void> {
    if (!this.constants.isDebug) {
      return;
    }

    globalModel.step2 = {
      energyPack: this.packsService.getForEnergy()[1],
      gasPack: this.packsService.getForGas()[2],
    };
  }
}
