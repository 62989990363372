/* eslint-disable max-len, @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { EnergyConsumptionModel, GasConsumptionModel } from '../models/consumptions-model';

@Injectable({
  providedIn: 'root',
})
export class ConsumptionsService {

  constructor() {
  }

  public async getForEnergy(): Promise<EnergyConsumptionModel[]> {
    const arr: EnergyConsumptionModel[] = [];
    for (const energyConsumption of this.energyConsumptions) {
      if (energyConsumption.patched) {
        continue;
      }
      energyConsumption.optionLabel = `${energyConsumption.consumptionValue} kWh - ${energyConsumption.houseType}`;
      energyConsumption.inputFormatted = `${energyConsumption.consumptionValue} kWh`;
      if (energyConsumption.surfaceInterval) {
        energyConsumption.optionLabel += ` (${energyConsumption.surfaceInterval} m<sup>2</sup>)`;
        energyConsumption.inputFormatted += ` (${energyConsumption.surfaceInterval} m<sup>2</sup>)`;
      }

      energyConsumption.patched = true;
    }
    arr.push(...this.energyConsumptions);
    return arr;
  }

  public async getForGas(): Promise<GasConsumptionModel[]> {
    const arr: GasConsumptionModel[] = [];

    for (const gasConsumption of this.gasConsumptions) {
      if (gasConsumption.patched) {
        continue;
      }
      gasConsumption.optionLabel = `${gasConsumption.consumptionValue} MWh (${gasConsumption.houseType} `
        + `de ${gasConsumption.surfaceInterval} m<sup>2</sup>) cat. ${gasConsumption.category}`;
      gasConsumption.inputFormatted = `${gasConsumption.consumptionValue} MWh  cat.  ${gasConsumption.category}`;
    }

    arr.push(...this.gasConsumptions);
    return arr;
  }

  private energyConsumptions: EnergyConsumptionModel[] = [
    {consumptionValue: 75, houseType: 'garsoniera', surfaceInterval: '0-25', isBold: true},
    {consumptionValue: 100, houseType: 'ap.1 camera', surfaceInterval: '25-40', isBold: true},
    {consumptionValue: 125, houseType: 'ap.1 camera', surfaceInterval: '25-40', isBold: true},
    {consumptionValue: 150, houseType: 'ap.2 camere', surfaceInterval: '40-55', isBold: true},
    {consumptionValue: 175, houseType: 'ap.2 camere', surfaceInterval: '40-55', isBold: true},
    {consumptionValue: 200, houseType: 'ap.2 camere', surfaceInterval: '55-75', isBold: true},
    {consumptionValue: 250, houseType: 'ap.3 camere', surfaceInterval: '55-75', isBold: true},
    {consumptionValue: 300, houseType: 'ap.3 camere', surfaceInterval: '55-75', isBold: true},
    {consumptionValue: 350, houseType: 'ap.3 camere', surfaceInterval: '75-90', isBold: true},
    {consumptionValue: 400, houseType: 'ap.4 camere', surfaceInterval: '90-100', isBold: true},
    {consumptionValue: 450, houseType: 'penthouse', surfaceInterval: '100-110', isBold: true},
    {consumptionValue: 500, houseType: 'casa mica', surfaceInterval: '110-130', isBold: true},
    {consumptionValue: 750, houseType: 'casa medie', surfaceInterval: '140-150'},
    {consumptionValue: 1000, houseType: 'casa normala', surfaceInterval: '150-200'},
    {consumptionValue: 1250, houseType: 'casa mare', surfaceInterval: '200-250'},
    {consumptionValue: 1500, houseType: 'vila', surfaceInterval: '250-350'},
    {consumptionValue: 1750, houseType: 'conac', surfaceInterval: '350-500'},
    {consumptionValue: 2000, houseType: 'castel', surfaceInterval: '500-700'},
    {consumptionValue: 2250, houseType: 'domeniu', surfaceInterval: '700-1000'},
    {consumptionValue: 2500, houseType: 'incalzire electrica'},
    {consumptionValue: 2750, houseType: 'incalzire electrica'},
    {consumptionValue: 3000, houseType: 'incalzire electrica'},
    {consumptionValue: 4000, houseType: 'incalzire electrica'},
    {consumptionValue: 5000, houseType: 'incalzire electrica'},
  ];

  private gasConsumptions: GasConsumptionModel[] = [
    {category: 'C1', dbColumn: '0p5', quantity: 47, consumptionValue: 0.5, houseType: 'garsoniera', surfaceInterval: '0-20', isBold: true},
    {
      category: 'C1',
      dbColumn: '0p75',
      quantity: 70,
      consumptionValue: 0.75,
      houseType: 'garsoniera',
      surfaceInterval: '20-30',
      isBold: true,
    },
    {category: 'C1', dbColumn: '1p0', quantity: 94, consumptionValue: 1, houseType: 'ap. 1 cam.', surfaceInterval: '30-40', isBold: true},
    {
      category: 'C1',
      dbColumn: '1p25',
      quantity: 117,
      consumptionValue: 1.25,
      houseType: 'ap. 2 cam.',
      surfaceInterval: '40-50',
      isBold: true,
    },
    {
      category: 'C1',
      dbColumn: '1p5',
      quantity: 141,
      consumptionValue: 1.5,
      houseType: 'ap. 2 cam.',
      surfaceInterval: '50-60',
      isBold: true,
    },
    {
      category: 'C1',
      dbColumn: '1p75',
      quantity: 164,
      consumptionValue: 1.75,
      houseType: 'ap.3 cam.',
      surfaceInterval: '60-70',
      isBold: true,
    },
    {category: 'C1', dbColumn: '2p0', quantity: 188, consumptionValue: 2, houseType: 'ap.3 cam.', surfaceInterval: '70-90', isBold: true},
    {category: 'C1', dbColumn: '4p0', quantity: 376, consumptionValue: 4, houseType: 'Asociații 2-3 ap.', surfaceInterval: '140-180'},
    {category: 'C1', dbColumn: '6p0', quantity: 563, consumptionValue: 6, houseType: 'Asociații 3-4 ap.', surfaceInterval: '280-360'},
    {category: 'C1', dbColumn: '8p0', quantity: 751, consumptionValue: 8, houseType: 'Asociații 5-6 ap.', surfaceInterval: '550-750'},
    // {category: 'C1', dbColumn: '11p0', quantity: 751, consumptionValue: 11, houseType: 'Asociații 7-8 ap.',  surfaceInterval: '1150-1500'},
    // {category: 'C1', dbColumn: '14p0', quantity: 751, consumptionValue: 14, houseType: 'Asociații 9-10 ap.',  surfaceInterval: '2300-2900 '},
    // {category: 'C1', dbColumn: '17p0', quantity: 751, consumptionValue: 17, houseType: 'Asociații 11-12 ap.',  surfaceInterval: '4500-5700 '},
    // {category: 'C1', dbColumn: '20p0', quantity: 1878, consumptionValue: 20, houseType: 'Asociații 14-15 ap.',  surfaceInterval: '9100-11500'},
    // {category: 'C1', dbColumn: '23p0', quantity: 1878, consumptionValue: 23, houseType: 'Asociații 17-18 ap.',  surfaceInterval: '18000-22000'},
  ];
}
