import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConstantsService } from '../../services/constants.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {

  public stepCount = 4;
  public steps: number[];

  @Input()
  public currentStep: number;

  @Output()
  public currentStepChange: EventEmitter<number> = new EventEmitter<number>();

  public get progressPercent(): number {
    if (this.currentStep === this.stepCount) {
      return 100;
    }
    return this.currentStep / (this.stepCount - 1) * 100;
  }

  constructor(
    private constants: ConstantsService,
  ) {
    this.steps = [...Array(this.stepCount).keys()].map(i => i + 1);
  }

  public stepClick(step: number): void {
    if (step > this.currentStep) {
      if (!this.constants.isDebug) {
        return;
      }
    }
    this.currentStep = step;
    this.currentStepChange.emit(step);
  }

}
