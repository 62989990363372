import { Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import scrollIntoView from 'scroll-into-view-if-needed';
import { TranslateService } from '@ngx-translate/core';
import { SvgIconsLoaderService } from './services/svg-icons-loader.service';
import { isPlatformBrowser } from '@angular/common';
import GlobalModel from './models/global-model';
import { DebugModelService } from './services/debug-model.service';
import { IframeService } from './services/iframe.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  @ViewChild(ProgressBarComponent, {read: ElementRef}) public progressBarElement: ElementRef;

  public title = 're-form';
  public step: number;

  public globalModel: GlobalModel = {};

  constructor(
    private iconsLoader: SvgIconsLoaderService,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) platformId: string,
    private debugService: DebugModelService,
    private iframeService: IframeService,
  ) {
    this.step = 1;
    this.iconsLoader.init();

    translate.addLangs(['ro', 'en']);
    // this language will be used as a fallback when a translation isn't found in the current language

    let langToSet: string = null;
    if (isPlatformBrowser(platformId)) {
      const split = window.location.pathname.split('/');
      langToSet = split.length >= 2 && split[1] || 'ro';

      if (!langToSet) {
        const browserLang = translate.getBrowserLang();
        langToSet = browserLang.match(/ro|en/) ? browserLang : 'ro';
      }
    }
    if (langToSet && translate.getLangs().indexOf(langToSet) >= 0) {
      translate.use(langToSet);
    } else {
      translate.use('ro');
    }
    this.debugService.init(this.globalModel).then();
  }


  public nextRequested(): void {
    scrollIntoView(this.progressBarElement.nativeElement, {behavior: 'smooth', scrollMode: 'if-needed'});
    this.iframeService.scrollParentTop();
    this.step++;
  }

  public backRequested(): void {
    scrollIntoView(this.progressBarElement.nativeElement, {behavior: 'smooth', scrollMode: 'if-needed'});
    this.iframeService.scrollParentTop();
    this.step--;
  }

  public resetToFirstRequested(): void {
    scrollIntoView(this.progressBarElement.nativeElement, {behavior: 'smooth', scrollMode: 'if-needed'});
    this.iframeService.scrollParentTop();
    this.step = 1;
  }
}
