import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PacksFeatureNameColumnComponent } from './components/packs-feature-name-column/packs-feature-name-column.component';
import { PacksPreviewColumnComponent } from './components/packs-preview-column/packs-preview-column.component';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PackDetailsComponent } from './components/pack-details/pack-details.component';
import { MiniCartComponent } from './components/mini-cart/mini-cart.component';
import { SharedModule } from '../components/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { PackPriceComponent } from './components/pack-price/pack-price.component';


@NgModule({
  declarations: [
    PacksFeatureNameColumnComponent,
    PacksPreviewColumnComponent,
    PackDetailsComponent,
    MiniCartComponent,
    PackPriceComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    AngularSvgIconModule,
    SharedModule,
    NgbPopoverModule,
  ],
  exports: [
    PacksFeatureNameColumnComponent,
    PacksPreviewColumnComponent,
    PackDetailsComponent,
    MiniCartComponent,
  ],
})
export class PacksModule {
}
