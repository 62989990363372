import { Directive, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator } from '@angular/forms';

@Directive()
export abstract class ReBaseInputDirective<T> implements OnInit, ControlValueAccessor, Validator {

  protected onChangeHook: (value: T) => void;
  protected onTouchedHook: (value: T) => void;
  protected onValidationHook: () => void;

  @Input()
  public label: string;
  @Input()
  public placeholder: string;

  public get isValid(): boolean {
    return this.control && this.control.valid;
  }

  public get isTouched(): boolean {
    return this.control && this.control.touched;
  }

  public get showAsInvalid(): boolean {
    return this.isTouched && !this.isValid;
  }

  protected value: T;

  protected onChangeCallRequired: boolean;
  protected onTouchedCallRequired: boolean;
  protected onValidationCallRequired: boolean;

  protected control: AbstractControl;

  protected onChange = (value: T) => {
    this.onChangeCallRequired = true;
  };
  protected onTouched = (_: any) => {
    this.onTouchedCallRequired = true;
  };

  protected onValidationChange: () => void = () => {
    this.onValidationCallRequired = true;
  };

  public ngOnInit(): void {
  }

  public registerOnChange(fn: (value) => void): void {
    if (this.onChangeHook) {
      this.onChange = (value) => {
        fn(value);
        this.onChangeHook(value);
      };
    } else {
      this.onChange = fn;
    }
    if (this.onChangeCallRequired) {
      setTimeout(() => {
        this.onChange(this.value);
      });
    }
  }

  public registerOnTouched(fn: any): void {
    if (this.onTouchedHook) {
      this.onTouched = (value) => {
        fn(value);
        this.onTouchedHook(value);
      };
    } else {
      this.onTouched = fn;
    }
    if (this.onTouchedCallRequired) {
      setTimeout(() => {
        this.onTouched(this.value);
      });
    }
  }

  public writeValue(obj: T): void {
    this.value = obj;
    this.onChange(this.value);
    this.onValidationChange();
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    if (this.control && control && this.control !== control) {
      console.log('different controls :(');
    }
    this.control = control;
    return null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    if (this.onValidationHook) {
      this.onValidationChange = () => {
        fn();
        this.onValidationHook();
      };
    } else {
      this.onValidationChange = fn;
    }
    if (this.onTouchedCallRequired) {
      setTimeout(() => {
        this.onValidationChange();
      });
    }
  }

}
