import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import PackModel, { FeatureModel } from '../../models/pack.model';
import { PacksService } from '../../packs.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-packs-preview-column',
  templateUrl: './packs-preview-column.component.html',
  styleUrls: ['./packs-preview-column.component.scss'],
})
export class PacksPreviewColumnComponent implements OnInit, OnDestroy {

  @Input()
  @HostBinding('class.is-last-column')
  public isLast: boolean;

  @HostBinding('class.is-selected')
  public get isSelected(): boolean {
    return this.pack && this.pack.selected;
  }

  @HostBinding('class.is-hovered')
  public get isHovered(): boolean {
    return this.pack && this.pack.hovered && !this.pack.selected;
  }

  @Input()
  public pack: PackModel;

  @Input()
  public showAllFeatures: boolean;
  @Output()
  public showAllFeaturesChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public features: FeatureModel[];

  @Output()
  public packSelected: EventEmitter<void> = new EventEmitter<void>();

  @ViewChildren('eventChild', {read: ElementRef}) public children: QueryList<ElementRef>;
  private subscription: Subscription;

  constructor(
    private packsService: PacksService,
  ) {
  }

  public async ngOnInit(): Promise<void> {
    this.features = await this.packsService.getFeaturesIds();
    this.subscription = this.children.changes.subscribe(x => {
      for (const elem of this.children.toArray()) {
        if (elem.nativeElement.eventPatched) {
          continue;
        }
        elem.nativeElement.eventPatched = true;
        elem.nativeElement.addEventListener('mouseenter', () => {
          if (!this.pack.hovered) {
            this.pack.hovered = true;
          }
        });
        elem.nativeElement.addEventListener('mouseleave', () => {
          this.pack.hovered = false;
        });
      }
    });
    this.children.notifyOnChanges();
  }


  public selectThisPack(): void {
    this.packSelected.emit();
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  public toggleAllFeatures(): void {
    this.showAllFeaturesChange.emit(!this.showAllFeatures);
  }
}
