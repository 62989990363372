<div class="pack-name">Pachetul de energie disponibil este</div>
<div class="h2 mb-0">
  <div [innerHTML]="'features.price-formatted' | translate"></div>
  <div [innerHTML]="'vat-included' | translate"></div>
</div>
<div>&nbsp;</div>

<ng-container *ngFor="let feature of features; let i = index;">
  <ng-container *ngIf="i === 3">
    <div>
      <div class="text-feature d-md-none">
        {{'step2.features' | translate}}
      </div>
    </div>
  </ng-container>
  <div [ngClass]="{'d-none': i >= 3 && !showAllFeatures}">
    <div class="green fw-bold text-feature" [innerHTML]="'features.' + feature.key | translate"></div>
    <div class="text-feature" [innerHTML]="'features.' + feature.key + '-desc' | translate"
         *ngIf="!feature.noDesc"></div>
  </div>
</ng-container>
