import { Directive, EventEmitter, Input, Output } from '@angular/core';
import GlobalModel from '../../models/global-model';

@Directive()
export abstract class StepBaseDirective {

  @Input()
  public globalModel: GlobalModel;

  @Output()
  public next: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public back: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public resetToFirst: EventEmitter<void> = new EventEmitter<void>();

  public onEnter(): void {
    console.log('a step enter');
  }

  public goNext(): void {
    this.next.emit();
  }

  public goBack(): void {
    this.back.emit();
  }
}
