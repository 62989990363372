<div class="pack-title" [ngClass]="'background-' + pack.class" (click)="selectThisPack()" #eventChild>
  <div class="top"></div>
  <div class="title px-1 px-md-3">
    <span [innerHTML]="'packs.'+pack.id | translate"></span>
    <div class="pack-icon">
      <img [src]="'assets/images/'+pack.type+'-red.svg'" alt=" "/>
    </div>
  </div>
  <div class="top-bottom">
    <svg-icon name="pack-header-bottom" class="top-bottom-svg"></svg-icon>
  </div>
</div>
<div class="price" (click)="selectThisPack()" #eventChild>
  <app-pack-price [pack]="pack" [whiteUnit]="pack.selected"></app-pack-price>
</div>
<div #eventChild>
  <button class="re-button re-button-sm"
          [class.green]="pack.hovered && !pack.selected"
          [class.yellow]="pack.selected"
          (click)="selectThisPack()">
    <span *ngIf="!pack.selected">{{'step2.choose' | translate}}</span>
    <span class="check" *ngIf="pack.selected">
      <svg-icon name="check" [svgStyle]="{width: 'auto', height: '1em'}"></svg-icon>
    </span>
  </button>
</div>
<ng-container *ngFor="let feature of features; let i = index;">
  <ng-container *ngIf="i === 3">
    <div>
      <div class="p-0 p-md-2 d-md-none">
        <button type="button" class="re-button re-button-sm" (click)="toggleAllFeatures()">
          <svg-icon name="expand" class="fill-white d-flex"
                    [svgStyle]="{'width.px': '18', 'height.px': '18', 'transform': 'rotate('+(showAllFeatures?180:0)+'deg)'}"></svg-icon>
        </button>
        <div *ngIf="!showAllFeatures" class="bottom" #eventChild (click)="selectThisPack()"></div>
      </div>
    </div>
  </ng-container>
  <div class="p-0 p-md-2" (click)="selectThisPack()" [ngClass]="{'d-none': i >= 3 && !showAllFeatures}" #eventChild>

    <ng-container *ngIf="feature.type === 'boolean'">
      <svg-icon name="check-circle" class="icon"
                [ngClass]="{'fill-green': pack.features[feature.key] && !pack.selected, 'fill-white': pack.features[feature.key] && pack.selected}"></svg-icon>
    </ng-container>
    <ng-container *ngIf="feature.type === 'string'">
      <span class="text-feature" [innerHTML]="'features.values.' + pack.features[feature.key] | translate"></span>
    </ng-container>

    <div *ngIf="i === features.length-1" class="bottom" #eventChild (click)="selectThisPack()"></div>
  </div>
</ng-container>
