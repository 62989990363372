import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import PackModel, { FeatureModel } from '../../models/pack.model';
import { PacksService } from '../../packs.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pack-details',
  templateUrl: './pack-details.component.html',
  styleUrls: ['./pack-details.component.scss'],
})
export class PackDetailsComponent implements OnInit {

  @Input()
  public pack: PackModel;
  @Input()
  public isX2 = false;

  public description: string;

  public features: FeatureModel[];

  @ViewChildren('popovers')
  public popovers: QueryList<NgbPopover>;

  constructor(
    private packsService: PacksService,
    public trans: TranslateService,
  ) {
  }

  public async ngOnInit(): Promise<void> {
    this.features = await this.packsService.getFeaturesIds();
  }
  //
  // public async showPopover(i: number, transKey: string, $event): Promise<void> {
  //   $event.preventDefault();
  //   $event.stopPropagation();
  //   const popover = this.popovers.toArray()[i];
  //   if (popover.isOpen()) {
  //     console.log('opening something already opened, returning');
  //     return;
  //   }
  //   popover.open({transKey});
  //   // this.popovers.toArray()[i].open({html: await this.trans.get(transKey).toPromise()});
  // }

}
