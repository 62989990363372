import { Directive, OnInit } from '@angular/core';
import { StepBaseDirective } from './step-base.directive';
import PackModel from '../../packs/models/pack.model';

@Directive()
export abstract class StepWithPacksDirective extends StepBaseDirective implements OnInit {
  public hasEnergy: boolean;
  public hasGas: boolean;

  public energyPack: PackModel;
  public gasPack: PackModel;

  public packs: PackModel[];

  protected constructor() {
    super();
  }
  public get isX2(): boolean {
    return this.packs.length === 2 && this.packs[0].id === 'energy-go-online' && this.packs[1].id === 'gas-go-online';
  }
  public ngOnInit(): void {
    setTimeout(() => {
      this.onEnter();
    }, 500);
    this.packs = [];
  }

  public onEnter(): void {
    this.packs = [];
    if (!this.globalModel.step1) {
      return;
    }
    this.hasEnergy = this.globalModel.step1.offerType && this.globalModel.step1.offerType !== 'gas';
    this.hasGas = this.globalModel.step1.offerType && this.globalModel.step1.offerType !== 'energy';
    if ((this.hasEnergy || this.hasGas) && !this.globalModel.step2) {
      console.log('no step2 when required');
      return;
    }
    if (this.hasEnergy) {
      this.energyPack = this.globalModel.step2.energyPack;
      this.packs.push(this.energyPack);
    }
    if (this.hasGas) {
      this.gasPack = this.globalModel.step2.gasPack;
      this.packs.push(this.gasPack);
    }
  }
}
