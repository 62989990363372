import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ReBaseInputDirective } from '../re-base-input.directive';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { HttpEventType, HttpParams } from '@angular/common/http';
import {
  defaultFileFieldConfig,
  FormlyFileFieldConfig,
  FormlyFileFieldState,
  PartialFormlyFileFieldConfig,
  ProcessedFile
} from './re-file-field.models';
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FilesService } from '../../../../services/files.service';


@Component({
  selector: 'app-re-file-field',
  templateUrl: './re-file-field.component.html',
  styleUrls: ['./re-file-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReFileFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReFileFieldComponent),
      multi: true,
    },
  ],
})
export class ReFileFieldComponent extends ReBaseInputDirective<any> implements OnInit, OnChanges {

  public isFileOver: boolean;
  private stateSubject: Subject<FormlyFileFieldState> = new Subject<FormlyFileFieldState>();

  @Input()
  public fieldConfig: PartialFormlyFileFieldConfig;

  public fieldConfigValue: FormlyFileFieldConfig;

  private stateValue: FormlyFileFieldState;

  public uploadTriggerSubscription: Subscription;

  public get state(): FormlyFileFieldState {
    return this.stateValue;
  }

  public set state(value: FormlyFileFieldState) {
    if (this.stateValue === value) {
      return;
    }
    this.stateValue = value;
    this.fieldConfigValue.state = value;
    this.fieldConfigValue.isValid = value === 'empty' && !this.fieldConfigValue.required || value === 'uploaded';
    this.stateSubject.next(value);
  }

  constructor(
    private filesService: FilesService,
    public translate: TranslateService,
  ) {
    super();
  }

  public processedFiles: ProcessedFile[] = [];

  public dropped(files: NgxFileDropEntry[]): void {
    this.isFileOver = false;

    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        if (!this.hasValidExtension(droppedFile)) {
          alert('Invalid file format! Allowed formats: ' + this.fieldConfigValue.accept);
          continue;
        }
        const processedFile: ProcessedFile = {droppedFile, state: 'added', name: droppedFile.relativePath};
        this.processedFiles.push(processedFile);

        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          processedFile.systemFile = file;
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        // so we can ignore it
        // const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        // console.log(droppedFile.relativePath, fileEntry);
      }
    }
    this.updateValueAndState();
  }

  public async remove(i: number): Promise<void> {
    // if (!confirm('Are you sure you want to delete this file? This action can\'t be undone.')) {
    if (!confirm(await this.translate.get('are-you-sure-delete-file').toPromise())) {
      return;
    }
    const processedFile = this.processedFiles[i];
    if (processedFile.state === 'uploaded') {
      console.log(processedFile);
      this.filesService.deleteFile(processedFile.backendFile.id).toPromise().then();
      // this.http.delete(this.fieldConfigValue.deleteUrl, {
      //   params: {
      //     id: processedFile.backendFile.id,
      //     ownerToken: processedFile.backendFile.ownerToken,
      //   },
      // }).toPromise().then();
    } else if (processedFile.state === 'uploading' && processedFile.uploadSubscription) {
      processedFile.uploadSubscription.unsubscribe();
      this.updateValueAndState();
    }
    this.processedFiles.splice(i, 1);
    this.updateValueAndState();
  }

  public retry(i: number): void {
    if (this.processedFiles[i].state === 'error') {
      this.beginUpload(this.processedFiles[i]);
    }
  }

  public updateValueAndState(): void {
    this.updateState();
    this.updateValue();
  }

  public updateState(): void {
    if (this.processedFiles.length === 0) {
      this.state = 'empty';
    } else if (this.processedFiles.some(file => file.state === 'uploading')) {
      this.state = 'uploading';
    } else if (this.processedFiles.some(file => file.state === 'error')) {
      this.state = 'error';
    } else if (this.processedFiles.every(file => file.state === 'uploaded')) {
      this.state = 'uploaded';
    } else {
      this.state = 'added';
    }
    if (this.showAsInvalid) {
      this.control.reset();
    }
  }

  public updateValue(): void {
    let value;
    // console.log('updateValue with ' + this.state);
    switch (this.state) {
      case 'uploaded':
        const buildValue = this.processedFiles.map(file => file.backendFile);
        if (this.fieldConfigValue.multiple) {
          value = buildValue;
        } else {
          value = buildValue[0];
        }
        break;
      default:
        value = undefined;
        break;
    }
    this.writeValue(value);
    if (this.state === 'error' && this.control) {
      this.control.setErrors({error: true});
    }
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.patchConfig();
    this.state = 'empty';

    this.loadInitialValue();
  }

  private loadInitialValue(): void {
    if (!this.control || !this.control.value) {
      return;
    }
    if (Array.isArray(this.control.value)) {
      for (const val of this.control.value) {
        this.processedFiles.push({backendFile: val, state: 'uploaded', name: val.originalName});
      }
    } else {
      const val = this.control.value;
      this.processedFiles.push({backendFile: val, state: 'uploaded', name: val.originalName});
    }
  }

  public onFileOver(): void {
    this.isFileOver = true;
  }

  public onFileLeave(): void {
    this.isFileOver = false;
  }

  public doUpload(): number {
    const filesToUpload = this.processedFiles.filter(file => file.state === 'added');

    if (!filesToUpload.length) {
      return 0;
    }

    for (const fileToUpload of filesToUpload) {
      this.beginUpload(fileToUpload);
    }
    this.updateValueAndState();
    return filesToUpload.length;
  }

  public beginUploadAtIndex(i: number): void {
    const filesToUpload = this.processedFiles.filter(file => file.state === 'added');
    const fileToUpload = filesToUpload[i];
    if (fileToUpload) {
      this.beginUpload(fileToUpload);
    }
  }

  public beginUpload(fileToUpload: ProcessedFile): void {
    fileToUpload.state = 'uploading';
    const formData = new FormData();
    formData.append('file', fileToUpload.systemFile, fileToUpload.systemFile.name);

    let params = new HttpParams();
    params = params.append('purpose', 'sessionFile');
    params = params.append('fileType', this.fieldConfigValue.purpose);

    fileToUpload.errorReason = null;

    fileToUpload.uploadSubscription = this.filesService.uploadFile(formData, params)
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          fileToUpload.progress = Math.round(event.loaded / event.total * 10000) / 100;
        }
        if (event.type === HttpEventType.Response) {
          // console.log(event);
          if (event.body && event.body.originalName) {
            fileToUpload.backendFile = {id: event.body.id};
            fileToUpload.state = 'uploaded';
            fileToUpload.uploadSubscription = null;
            this.updateValueAndState();
          } else {
            console.log('error ');
            throw new Error(event.body);
          }
        }
      }, error => {
        console.log('file error 2: ', error?.error);
        fileToUpload.state = 'error';
        fileToUpload.uploadSubscription = null;
        if (error && error.error && typeof error.error.error === 'string') {
          fileToUpload.errorReason = error.error.error;
        }
        console.log(error);
        this.updateValueAndState();
      });
  }

  private hasValidExtension(file: NgxFileDropEntry): boolean {
    if (!this.fieldConfigValue.accept) {
      return true;
    }
    if (!this.fieldConfigValue.acceptArr) {
      this.fieldConfigValue.acceptArr = this.fieldConfigValue.accept.split(',').map(acc => acc.toLowerCase());
    }
    if (!file.fileEntry || !file.fileEntry.name) {
      return false;
    }
    return this.fieldConfigValue.acceptArr.some(accExt => file.fileEntry.name.toLowerCase().endsWith(accExt.toLowerCase()));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.fieldConfig) {
      if (this.fieldConfigValue === this.fieldConfig) {
        return;
      }
      this.unbindConfig();
      this.patchConfig();
    }
  }

  private patchConfig(): void {
    this.fieldConfigValue = Object.assign({}, defaultFileFieldConfig, this.fieldConfig);
    this.fieldConfigValue.stateChanged = this.stateSubject.asObservable();
    this.fieldConfigValue.uploadTrigger = new EventEmitter<any>();
    this.uploadTriggerSubscription = this.fieldConfigValue.uploadTrigger.subscribe(() => {
      this.doUpload();
    });
  }

  private unbindConfig(): void {
    if (this.uploadTriggerSubscription) {
      this.uploadTriggerSubscription.unsubscribe();
      this.uploadTriggerSubscription = null;
    }
  }
}
