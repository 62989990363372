import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import PackModel from '../../models/pack.model';
import PackExtraOptionModel from '../../models/pack-extra-option.model';

@Component({
  selector: 'app-mini-cart',
  templateUrl: './mini-cart.component.html',
  styleUrls: ['./mini-cart.component.scss'],
})
export class MiniCartComponent implements OnChanges {

  @Input()
  public packs: PackModel[];

  @Input()
  public isX2 = false;

  // public total: number;

  public ngOnChanges(changes: SimpleChanges): void {
    // if (this.packs) {
    //   let total = 0;
    //   for (const pack of this.packs) {
    //     total += pack.monthlyPrice;
    //   }
    //   this.total = total;
    // }
  }


  public getMonthlyPriceForPack(pack: PackModel): number {
    return this.isX2 ? pack.monthlyPriceX2 : pack.monthlyPrice;
  }

  public get pricesLoading(): boolean {
    return this.packs && this.packs.some(pack => pack && pack.priceLoading);
  }

  public get total(): number {
    if (!this.packs) {
      return 0;
    }
    return this.packs.reduce((acc: number, pack: PackModel) => acc +
      (pack ? (this.getMonthlyPriceForPack(pack) +
        ((pack.extraDailyPrice || 0) * 30) +
        pack.extraOptions.reduce((acc2: number, eo: PackExtraOptionModel) =>
          acc2 + (pack.extraOptionsState[eo.id] ? eo.monthPrice : 0), 0)) : 0), 0);
  }
}
