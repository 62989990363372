import { NgModule } from '@angular/core';

import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { AngularSvgIconModule, SvgLoader } from 'angular-svg-icon';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { svgBrowserLoaderFactory } from './services/ssr-browser-services/svg-browser-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { translateBrowserLoaderFactory } from './services/ssr-browser-services/translate-browser-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    AppModule,
    HttpClientModule,
    BrowserModule,
    // BrowserTransferStateModule,
    AngularSvgIconModule.forRoot({
      loader: {
        provide: SvgLoader,
        useFactory: svgBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
      defaultLanguage: 'ro',
    }),
    BrowserAnimationsModule,
  ],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {
}
