import { Component, Input, OnInit } from '@angular/core';
import { PacksService } from '../../packs.service';
import { FeatureModel } from '../../models/pack.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-packs-feature-name-column',
  templateUrl: './packs-feature-name-column.component.html',
  styleUrls: ['./packs-feature-name-column.component.scss'],
})
export class PacksFeatureNameColumnComponent implements OnInit {

  public features: FeatureModel[];

  @Input()
  public showAllFeatures: boolean;

  constructor(
    private packsService: PacksService,
    public trans: TranslateService,
  ) {
  }

  public async ngOnInit(): Promise<void> {
    this.features = await this.packsService.getFeaturesIds();
  }
}
