import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionStorageService } from 'ngx-webstorage';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root',
})
export class FilesService {

  constructor(
    private http: HttpClient,
    private constants: ConstantsService,
    private sessionSt: SessionStorageService,
  ) {
  }

  public uploadFile(formData: FormData, params: HttpParams): Observable<any> {
    const url = this.constants.apiUrl + 'SessionFilesApi/Upload';
    params = params.append('sessionId', this.sessionSt.retrieve('session_id'));
    return this.http.post<any>(url,
      formData, {
        reportProgress: true,
        observe: 'events',
        params,
      });
  }

  public deleteFile(id: string): Observable<any> {
    const url = this.constants.apiUrl + 'SessionFilesApi/DeleteUploadedFile';
    const sessionId = this.sessionSt.retrieve('session_id');
    const data = {sessionId, id};
    return this.http.post(url, null, {params: data});
  }
}
