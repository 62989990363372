import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SharedModule } from './components/shared/shared.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule } from '@angular/common/http';
import { StepOneComponent } from './components/steps/step-one/step-one.component';
import { StepTwoComponent } from './components/steps/step-two/step-two.component';
import { StepThreeComponent } from './components/steps/step-three/step-three.component';
import { StepFourComponent } from './components/steps/step-four/step-four.component';
import { ReCarouselComponent } from './components/re-carousel/re-carousel.component';
import { ReCarouselSlideComponent } from './components/re-carousel/re-carousel-slide/re-carousel-slide.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PacksModule } from './packs/packs.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular';

@NgModule({
  declarations: [
    AppComponent,
    ProgressBarComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    StepFourComponent,
    ReCarouselComponent,
    ReCarouselSlideComponent,
  ],
  imports: [
    BrowserModule, //.withServerTransition({appId: 'serverApp'}),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PacksModule,
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    NgbPopoverModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
