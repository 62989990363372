import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 're-carousel-slide',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./re-carousel-slide.component.scss'],
})
export class ReCarouselSlideComponent {

  @Input() public stepIndex: number;
  @Output()
  public slideEnter: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('class.animated')
  public animated = true;

  @Input()
  @HostBinding('class.visible')
  public visible: boolean;
  @HostBinding('class.animating')
  public animating: boolean;

  @HostBinding('class.fadeInLeft')
  public slideInLeft: boolean;
  @HostBinding('class.fadeOutLeft')
  public slideOutLeft: boolean;
  @HostBinding('class.fadeInRight')
  public slideInRight: boolean;
  @HostBinding('class.fadeOutRight')
  public slideOutRight: boolean;


  public showFromLeft(): void {
    this.updateState(0);
    this.visible = true;
    this.slideEnter.emit();
  }

  public showFromRight(): void {
    this.updateState(2);
    this.visible = true;
    this.slideEnter.emit();
  }

  public hideToLeft(): void {
    this.updateState(1);
    this.animating = true;
    setTimeout(() => {
      this.visible = false;
      this.animating = false;
    }, 1000);
  }

  public hideToRight(): void {
    this.updateState(3);
    this.animating = true;
    setTimeout(() => {
      this.visible = false;
      this.animating = false;
    }, 1000);
  }

  public justShow(): void {
    this.visible = true;
  }

  public updateState(state: number): void {
    this.slideInLeft = state === 0;
    this.slideOutLeft = state === 1;
    this.slideInRight = state === 2;
    this.slideOutRight = state === 3;
  }
}
